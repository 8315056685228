import { useMemo } from "react"
import { gql } from "~/__generated__"
import { CachedViewerContextFragment, ProfilePictureColor } from "~/__generated__/graphql"
import { AppText } from "~/ui/app-text"
import { cn } from "./cn"
import imgixResize from "./imgix-resize"

const sizes = {
  menu: {
    width: 40,
    height: 40,
  },
} as const

export type AvatarSize = keyof typeof sizes

const fontSizes = {
  menu: "text-15",
}

type BaseProps = {
  size: keyof typeof sizes
  onPress?: () => void
}

export const UserAvatarWithFallback = ({
  user,
  ...rest
}: BaseProps & {
  user: CachedViewerContextFragment
}) => <AvatarWithFallback url={user.avatarUrl} name={user.displayOrUsername} {...rest} />

export type AvatarWithFallbackProps = BaseProps & {
  url: string | undefined | null
  name: string
  profilePictureColor?: ProfilePictureColor | null
}

export const AvatarWithFallback = ({
  url,
  name,
  size,
  onPress,
  profilePictureColor,
}: AvatarWithFallbackProps) => {
  const sizeStyle = sizes[size]
  const fallbackColors = useMemo(() => {
    return profilePictureColor
      ? bgColorMap[profilePictureColor]
      : Object.values(bgColorMap)[keyFromString(name)]
  }, [name, profilePictureColor])

  const avatar = useMemo(() => {
    const sharedStyle = {
      ...sizeStyle,
    }
    if (!url) {
      return (
        <div className={cn(sharedStyle, fallbackColors)}>
          <AppText className={cn("opacity-70", fontSizes[size], fallbackColors)}>
            {name.charAt(0) || ""}
          </AppText>
        </div>
      )
    } else {
      const imgixUrl = imgixResize(url, { ...sizeStyle, fit: "crop" })
      return (
        <div style={sharedStyle}>
          <img src={imgixUrl} style={sharedStyle} />
        </div>
      )
    }
  }, [fallbackColors, name, sizeStyle, size, url])

  return (
    <button disabled={!onPress} onClick={onPress} className="flex overflow-hidden rounded-full">
      {avatar}
    </button>
  )
}

gql(/* GraphQL */ `
  fragment User_Avatar on User {
    id
    avatarUrl
    displayOrUsername
  }
`)

const bgColorMap: Record<ProfilePictureColor, string> = {
  [ProfilePictureColor.Blue]: "bg-support-blue text-base-white",
  [ProfilePictureColor.Clay]: "bg-support-clay text-base-black",
  [ProfilePictureColor.Cream]: "bg-support-cream text-base-black",
  [ProfilePictureColor.Green]: "bg-support-green text-base-black",
  [ProfilePictureColor.Lime]: "bg-support-lime text-base-black",
  [ProfilePictureColor.Magenta]: "bg-support-magenta text-base-black",
  [ProfilePictureColor.Midnight]: "bg-support-midnight text-base-black",
  [ProfilePictureColor.Moss]: "bg-support-moss text-base-black",
  [ProfilePictureColor.Orange]: "bg-support-orange text-base-black",
  [ProfilePictureColor.Pink]: "bg-support-pink text-base-black",
  [ProfilePictureColor.Purple]: "bg-support-purple text-white",
  [ProfilePictureColor.Red]: "bg-support-red text-base-black",
  [ProfilePictureColor.Sky]: "bg-support-sky text-base-black",
  [ProfilePictureColor.SoftLime]: "bg-support-soft-lime text-base-black",
  [ProfilePictureColor.Yellow]: "bg-support-yellow text-base-black",
}

const keyFromString = (input: string): number => {
  let hash = 0

  for (let i = 0; i < input.length; i++) {
    hash = (hash << 5) - hash + input.charCodeAt(i)
    hash &= hash // Convert to 32bit integer
  }

  const min = 0
  const max = 14
  const range = max - min + 1
  const randomNumber = (((hash % range) + range) % range) + min

  return randomNumber
}
