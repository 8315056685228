import { useEffect } from "react"
import { getMetaContent } from "~/common/get-meta-content"

const APP_STORE_URL = getMetaContent("APP_STORE_URL")
const PLAY_STORE_URL = getMetaContent("PLAY_STORE_URL")

const getRedirectTo = () => {
  const userAgent = window.navigator.userAgent
  if (/iP(hone|ad|od)/.test(userAgent)) {
    return APP_STORE_URL
  } else if (/Android/.test(userAgent)) {
    return PLAY_STORE_URL
  }
  return null
}

export const DownloadScreen = () => {
  useEffect(() => {
    const redirectTarget = getRedirectTo()
    if (redirectTarget) {
      window.location.href = redirectTarget
    }
  })

  return (
    <div data-testid="download-screen">
      <a className="text-base-blue underline" href={APP_STORE_URL}>
        Download on App Store
      </a>
      <br />
      <br />
      <a className="text-base-blue underline" href={PLAY_STORE_URL}>
        Download on Play Store
      </a>
    </div>
  )
}
