import "./landing.css"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import logo from "~/images/landing/logo.ts"
import star from "~/images/landing/star.ts"
import title from "~/images/landing/title.ts"
import caretDown from "~/images/landing/caret-down.ts"
import bachelor from "~/images/landing/carousel/bachelor"
import discoveryCardMultiplePeople from "~/images/landing/carousel/discovery-card-multiple-people"
import nfl02 from "~/images/landing/carousel/nfl-02"
import nfl03 from "~/images/landing/carousel/nfl-03"
import smartmoney02 from "~/images/landing/carousel/smartmoney-02"
import { Link } from "~/ui/link"
import { useMediaQuery } from "~/hooks/use-media-query"
import { AppText } from "~/ui/app-text"
import { useState } from "react"

const TERMS_TEXT_CLASSES = "mt-2 text-[#141010]/70 lg:mt-4"

const TermsAndPrivacyText = () => (
  <>
    By using ChatBCC, you agree to our{" "}
    <Link className="underline" to={"../terms"}>
      Terms
    </Link>{" "}
    &{" "}
    <Link className="underline" to={"../privacy"}>
      Privacy Policy
    </Link>
  </>
)

export const LandingScreen = () => {
  const isLargeScreen = useMediaQuery("(min-width: 1024px)")
  const [phoneNumber, setPhoneNumber] = useState("")

  const formatPhoneNumber = (value: string) => {
    // Remove all non-digit characters
    const digits = value.replace(/\D/g, "")

    // Format the number as you type
    let formattedNumber = ""
    if (digits.length === 0) {
      formattedNumber = ""
    } else if (digits.length <= 3) {
      formattedNumber = `(${digits}`
    } else if (digits.length <= 6) {
      formattedNumber = `(${digits.slice(0, 3)}) ${digits.slice(3)}`
    } else {
      formattedNumber = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`
    }

    return formattedNumber
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = formatPhoneNumber(e.target.value)
    setPhoneNumber(formattedNumber)
  }

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 750,
    swipe: !isLargeScreen,
    swipeToSlide: !isLargeScreen,
    waitForAnimate: true,
    touchMove: !isLargeScreen,
    touchThreshold: 3,
    focusOnSelect: true,
    draggable: !isLargeScreen,
    slidesToShow: isLargeScreen ? 3 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnFocus: true,
    pauseOnHover: true,
    vertical: isLargeScreen,
    verticalSwiping: isLargeScreen,
    centerMode: false,
    arrows: false,
    cssEase: "ease-in-out",
  }

  const carouselImageClasses = "rounded-[30px] lg:max-w-[400px] max-lg:mx-auto lg:ml-auto"

  return (
    <div className="relative min-h-screen overflow-visible bg-[#2D92FF]">
      {/* Main content */}
      <div className="mx-auto max-w-7xl px-8 py-8 lg:py-16">
        {/* Decorative stars */}
        <div className="absolute left-[49%] top-[60%] max-sm:hidden">
          <img src={star.src} alt="" className="h-[37px] w-[39px] animate-pulse" />
        </div>
        <div className="absolute left-[79%] top-[40%] hidden">
          <img src={star.src} alt="" className="h-[37px] w-[39px] animate-pulse" />
        </div>

        {/* Content grid */}
        <div className="mx-auto grid max-w-[540px] grid-cols-1 gap-6 lg:max-w-[1080px] lg:grid-cols-2 lg:gap-16">
          {/* Left column */}
          <div className="space-y-4 lg:space-y-12">
            {/* Logo section */}
            <div className="mb-6 flex justify-center lg:mb-24 lg:justify-start">
              <img
                src={logo.src}
                alt="ChatBCC Logo"
                width={logo.width}
                height={logo.height}
                className="h-[50px] w-[124px] lg:h-[69px] lg:w-[171px]"
                loading="eager"
              />
            </div>

            <div className="space-y-3 lg:space-y-8">
              {/* Decorative stars */}
              <div className="absolute">
                <div className="relative -right-[20px] -top-[60px]">
                  <img src={star.src} alt="" className="h-[37px] w-[39px] animate-pulse" />
                </div>
              </div>
              <div className="mb-3 lg:mb-8">
                <img
                  src={title.src}
                  alt="A Front Row Seat to Epic Group Chats"
                  className="h-[180px] w-[400px] sm:h-[210px] sm:w-[470px] lg:h-[245px] lg:w-[540px]"
                  loading="eager"
                />
                {/* Alternative pure text version instead of using an SVG image for the title:

                <div className="text-[min(8vw,_12rem)] sm:text-[min(10vw,_16rem)] font-black tracking-tighter leading-none whitespace-wrap">
                  <span className="text-[#141010] font-extrabold">A FRONT ROW SEAT TO </span>
                  <span className="text-[#FFC700] font-extrabold [-webkit-text-stroke:2px_#141010] [text-stroke:2px_#141010]">
                    EPIC GROUP CHATS
                  </span>
                </div>
                */}
              </div>
              <AppText
                variant={isLargeScreen ? "body1" : "body2"}
                className="max-w-[540px] text-[#141010]"
              >
                Amazing people having real conversations about topics you love. Follow your
                favorites or start your own.
              </AppText>
            </div>

            {/* Phone input section */}
            <div className="grid grid-cols-1 gap-2 max-lg:max-w-[540px] sm:grid-cols-2 lg:gap-4">
              <div className="flex h-[48px] items-center rounded-lg border border-[#141010] bg-[#FCFFF9] max-sm:hidden">
                <div className="ml-4 flex w-full items-center">
                  <select
                    className="text-body2 flex h-[27px] w-[80px] cursor-pointer appearance-none items-center gap-1 rounded-full bg-[#E8E8E4] px-2"
                    defaultValue="US"
                  >
                    <option value="US">🇺🇸 +1</option>
                    <option value="CA">🇨🇦 +1</option>
                    <option value="GB">🇬🇧 +44</option>
                    <option value="FR">🇫🇷 +33</option>
                    <option value="DE">🇩🇪 +49</option>
                    <option value="IT">🇮🇹 +39</option>
                    <option value="ES">🇪🇸 +34</option>
                    <option value="AU">🇦🇺 +61</option>
                    <option value="JP">🇯🇵 +81</option>
                    <option value="CN">🇨🇳 +86</option>
                  </select>
                  <div className="pointer-events-none absolute ml-[60px]">
                    <img src={caretDown.src} alt="dropdown" className="h-[12px] w-[12px]" />
                  </div>
                  <input
                    type="tel"
                    placeholder="(555) 555-1234"
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    maxLength={14}
                    className="text-body2 ml-4 w-full border-none bg-transparent text-[#141010] placeholder-[#141010]/50 outline-none"
                  />
                </div>
              </div>
              <div>
                <Link to={"../download"} className="block">
                  <button className="flex h-[48px] w-full items-center justify-center rounded-full border border-[#141010] bg-[#FD905B] px-4 py-3 text-[#141010] transition-colors hover:bg-[#FD905B]/90 lg:w-[215px]">
                    <AppText variant="button">
                      <strong>GET THE APP</strong>
                    </AppText>
                  </button>
                </Link>
              </div>
            </div>
            <AppText variant="caption" className={`${TERMS_TEXT_CLASSES} max-lg:hidden`}>
              <TermsAndPrivacyText />
            </AppText>
          </div>

          {/* Right column - Carousel */}
          <div>
            <div className="carousel-mask overflow-hidden max-lg:max-w-[540px]">
              <div className="max-lg:mx-auto max-lg:w-full max-lg:max-w-[540px] lg:ml-auto lg:-translate-y-[342px]">
                <Slider {...sliderSettings} className="lg:h-[768px] lg:-translate-y-[-171px]">
                  <div>
                    <img
                      src={bachelor.src}
                      alt="The Bachelor chat"
                      className={carouselImageClasses}
                    />
                  </div>
                  <div>
                    <img
                      src={discoveryCardMultiplePeople.src}
                      alt="Discovery Card chat with multiple people"
                      className={carouselImageClasses}
                    />
                  </div>
                  <div>
                    <img src={nfl02.src} alt="NFL chat" className={carouselImageClasses} />
                  </div>
                  <div>
                    <img src={nfl03.src} alt="NFL chat" className={carouselImageClasses} />
                  </div>
                  <div>
                    <img
                      src={smartmoney02.src}
                      alt="Smartmoney chat"
                      className={carouselImageClasses}
                    />
                  </div>
                </Slider>
              </div>
            </div>
            <AppText
              variant="caption"
              className={`${TERMS_TEXT_CLASSES} max-w-[540px] text-center lg:hidden`}
            >
              <TermsAndPrivacyText />
            </AppText>
          </div>
        </div>
      </div>
    </div>
  )
}
