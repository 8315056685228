import { AppText } from "~/ui/app-text"
import { Link } from "~/ui/link"
import logo from "~/images/landing/logo.ts"

type FAQItem = {
  question: string
  answer: React.ReactNode
}

const faqs: FAQItem[] = [
  {
    question: "What is ChatBCC?",
    answer:
      "ChatBCC is a platform where you can follow and participate in real conversations about topics you love. It's like having a front row seat to epic group chats, where amazing people discuss everything from sports to entertainment to finance.",
  },
  {
    question: "How do I get started?",
    answer:
      "Download the ChatBCC mobile app from the App Store or Google Play Store. Once installed, you can create an account using your phone number and start following conversations that interest you.",
  },
  {
    question: "How can I contact support?",
    answer: (
      <span>
        For any questions or concerns, please email us at{" "}
        <a href="mailto:support@chatbcc.com" className="text-blue-600 hover:underline">
          support@chatbcc.com
        </a>
        . Our support team is available to help you Monday through Friday, 9 AM to 5 PM PST.
      </span>
    ),
  },
  {
    question: "Is ChatBCC free?",
    answer:
      "Yes, ChatBCC is currently free to use. We may introduce premium features in the future, but we'll always maintain free options for our users.",
  },
  {
    question: "How do I report inappropriate content?",
    answer: (
      <span>
        If you see content that violates our terms of service or community guidelines, please report
        it immediately by contacting us at{" "}
        <a href="mailto:support@chatbcc.com" className="text-blue-600 hover:underline">
          support@chatbcc.com
        </a>
        . We take all reports seriously and will investigate promptly.
      </span>
    ),
  },
  {
    question: "What devices can I use ChatBCC on?",
    answer: "ChatBCC is currently available on iOS and Android devices.",
  },
  {
    question: "How do I delete my account?",
    answer: (
      <span>
        To delete your account, please email us at{" "}
        <a href="mailto:support@chatbcc.com" className="text-blue-600 hover:underline">
          support@chatbcc.com
        </a>
        . Please note that account deletion is permanent and cannot be undone.
      </span>
    ),
  },
  {
    question: "Is my data private and secure?",
    answer: (
      <span>
        We take your privacy and security seriously. Please review our{" "}
        <Link to="../privacy" className="text-blue-600 hover:underline">
          Privacy Policy
        </Link>{" "}
        to learn more about how we handle your data.
      </span>
    ),
  },
]

export const SupportScreen = () => {
  return (
    <div className="min-h-screen bg-white">
      <div className="mx-auto max-w-4xl px-4 py-16">
        {/* Logo */}
        <div className="mb-8 flex justify-center">
          <img
            src={logo.src}
            alt="ChatBCC Logo"
            width={logo.width}
            height={logo.height}
            className="h-[69px] w-[171px]"
            loading="eager"
          />
        </div>

        {/* Header */}
        <div className="mb-8 text-center">
          <AppText variant="h1" className="mb-4">
            Support & FAQ
          </AppText>
          <AppText variant="body1" className="text-neutral-600">
            Find answers to commonly asked questions about ChatBCC
          </AppText>
        </div>

        {/* FAQ Section */}
        <div className="space-y-8">
          {faqs.map((faq, index) => (
            <div key={index} className="border-b border-neutral-200 pb-8">
              <AppText variant="h3" className="mb-4">
                {faq.question}
              </AppText>
              <AppText variant="body1" className="text-neutral-600">
                {faq.answer}
              </AppText>
            </div>
          ))}
        </div>

        {/* Contact Section */}
        <div className="mt-16 space-y-8 rounded-lg bg-neutral-50 p-8 text-center">
          <AppText variant="h3" className="mb-4">
            Still need help?
          </AppText>
          <AppText variant="body1" className="mb-6 text-neutral-600">
            Our support team is here to assist you with any questions or concerns.
          </AppText>
          <a
            href="mailto:support@chatbcc.com"
            className="inline-flex h-12 items-center justify-center rounded-full bg-[#2D92FF] px-8 text-white transition-colors hover:bg-[#2D92FF]/90"
          >
            <strong>Contact Support</strong>
          </a>
        </div>
      </div>
    </div>
  )
}
