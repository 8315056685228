import "./privacy.css"

// Link to doc: https://docs.google.com/document/d/1Nygrcs3t87bccV22ndmr4KS4FJTxghEy91ggJAGOvLU/edit?usp=sharing
// Export as HTML and then beautify with: https://www.site24x7.com/tools/html-beautifier.html
// Styled with CSS Nesting: https://medium.com/@rapPayne/stop-writing-react-local-styles-use-the-component-classname-pattern-9a820c6447de

export const privacyContent = `
    <p class="c46"><span class="c12">Privacy Policy</span></p>
    <p class="c24"><span class="c1 c41">Effective date: January 17, 2025</span></p>
    <p class="c24 c33"><span class="c1 c41"></span></p>
    <p class="c9"><span>At ChatBCC, we take your privacy seriously. Please read this Privacy Policy to learn how we treat your personal data. </span><span class="c28">By using or accessing our Services in any manner, you acknowledge that you accept the practices and policies outlined below, and you hereby consent that we will collect, use and disclose your information as described in this Privacy Policy.</span></p>
    <p
    class="c24"><span class="c41">Remember that your</span><span>&nbsp;use of chatBCC&#39;s Services is at all times subject to our Terms of Use, </span><span class="c23"><a class="c22" href="https://chatbcc.com/terms">https://chatbcc.com/terms</a></span><span>, </span>
        <span
        class="c1">which incorporates this Privacy Policy. Any terms we use in this Policy without defining them have the definitions given to them in the Terms of Use.</span>
            </p>
            <p class="c24"><span>You may print a copy of this Privacy Policy by clicking here: </span><span class="c23"><a class="c22" href="https://chatbcc.com/privacy">https://chatbcc.com/privacy</a></span><span>.</span></p>
            <p
            class="c24"><span class="c1">As we continually work to improve our Services, we may need to change this Privacy Policy from time to time. We will alert you of material changes by placing a notice on the chatBCC website, by sending you an email and/or by some other means. Please note that if you&rsquo;ve opted not to receive legal notice emails from us (or you haven&rsquo;t provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes.</span></p>
                <p
                class="c47"><span class="c34 c28">Privacy Policy Table of Contents</span></p>
                    <p class="c43"><span class="c8"><a class="c22" href="#h.eqv0w7bc9njg">What this Privacy Policy Covers</a></span></p>
                    <p class="c43"><span class="c8"><a class="c22" href="#h.75dd5hnhdzqb">Personal Data</a></span></p>
                    <p class="c11"><span class="c8"><a class="c22" href="#h.qr4i6pvqxdb0">Categories of Personal Data We Collect</a></span></p>
                    <p class="c11"><span class="c8"><a class="c22" href="#h.w6s63ed86cj0">Our Commercial or Business Purposes for Collecting Personal Data</a></span></p>
                    <p class="c11"><span class="c8"><a class="c22" href="#h.ax0sfyj7j70i">Other Permitted Purposes for Processing Personal Data</a></span></p>
                    <p class="c11"><span class="c8"><a class="c22" href="#h.qcdockvh0lur">Categories of Sources of Personal Data</a></span></p>
                    <p class="c43"><span class="c8"><a class="c22" href="#h.wx7u6m5734yr">How We Disclose Your Personal Data</a></span></p>
                    <p class="c43"><span class="c8"><a class="c22" href="#h.u1vcux1tfcp7">Tracking Tools, Advertising, and Opt-Out</a></span></p>
                    <p class="c43"><span class="c8"><a class="c22" href="#h.haf8z3yb8qkw">Data Security</a></span></p>
                    <p class="c43"><span class="c8"><a class="c22" href="#h.e4omy61mhttz">Data Retention</a></span></p>
                    <p class="c43"><span class="c8"><a class="c22" href="#h.hw0ypqccnog9">Personal Data of Children</a></span></p>
                    <p class="c43"><span class="c8"><a class="c22" href="#h.vzykhp1rgvtl">Other State Law Privacy Rights</a></span></p>
                    <p class="c43"><span class="c8"><a class="c22" href="#h.dzwlxfar7jus">Contact Information</a></span></p>
                    <p class="c9 c33"><span class="c12"></span></p>
                    <h2 class="c24 c18" id="h.eqv0w7bc9njg"><span class="c12">What this Privacy Policy Covers</span></h2>
                    <p class="c24"><span class="c1">This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services. &ldquo;Personal Data&rdquo; means any information that identifies or relates to a particular individual and also includes information referred to as &ldquo;personally identifiable information&rdquo; or &ldquo;personal information&rdquo; under applicable data privacy laws, rules or regulations. This Privacy Policy does not cover the practices of companies we don&rsquo;t own or control or people we don&rsquo;t manage.</span></p>
                    <h2
                    class="c24 c18" id="h.75dd5hnhdzqb"><span class="c12">Personal Data</span></h2>
                        <h3 class="c9 c18" id="h.qr4i6pvqxdb0"><span class="c15">Categories of Personal Data We Collect</span></h3>
                        <p class="c9"><span class="c1">This chart details the categories of Personal Data that we collect and have collected over the past 12 months:</span></p>
                        <table class="c53">
                            <tr class="c32">
                                <td class="c4 c27" colspan="1" rowspan="1">
                                    <p class="c38"><span class="c25 c28 c44">Category of Personal Data (and Examples)</span></p>
                                </td>
                                <td class="c4 c27" colspan="1" rowspan="1">
                                    <p class="c38"><span class="c28">Business or Commercial Purpose(s) for Collection</span></p>
                                </td>
                                <td class="c4 c27" colspan="1" rowspan="1">
                                    <p class="c38"><span class="c28">Categories of Third Parties With Whom We Disclose this Personal Data</span></p>
                                </td>
                            </tr>
                            <tr class="c42">
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c13">Profile or Contact Data</span><span class="c5">&nbsp;such as first and last name, email, username, phone number and user&#39;s bio and social media profile links.</span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c16">-Providing, Customizing and Improving the Services</span></p>
                                    <p class="c3"><span class="c16">-Marketing the Services</span></p>
                                    <p class="c3"><span class="c16">-Corresponding with You</span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Service Providers</span></p>
                                </td>
                            </tr>
                            <tr class="c26">
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c13">Payment Data</span><span>&nbsp;</span><span class="c16">such as billing address.</span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Providing, Customizing and Improving the Services</span></p>
                                    <p class="c3 c31"><span class="c5"></span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c16">-Service Providers</span><span class="c16 c51">&nbsp;</span><span class="c5">(specifically our payment processing partners, currently Apple Pay and Google Pay)</span></p>
                                </td>
                            </tr>
                            <tr class="c20">
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c13">Device/IP Data</span><span class="c5">&nbsp;such as IP address, device ID, domain server and type of device/operating system/browser used to access the Services.</span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Providing, Customizing and Improving the Services</span></p>
                                    <p class="c3"><span class="c5">-Marketing the Services</span></p>
                                    <p class="c3 c31"><span class="c5"></span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Service Providers</span></p>
                                    <p class="c3"><span class="c5">-Advertising Partners</span></p>
                                </td>
                            </tr>
                            <tr class="c35">
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c13">Web Analytics</span><span class="c5">&nbsp;such as web page interactions, referring webpage/source through which you accessed the Services, non-identifiable request IDs and statistics associated with the interaction between device or browser and the Services.</span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Providing, Customizing and Improving the Services</span></p>
                                    <p class="c3"><span class="c5">-Marketing the Services</span></p>
                                    <p class="c3 c31"><span class="c5"></span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Service Providers</span></p>
                                    <p class="c3"><span class="c5">-Advertising Partners</span></p>
                                </td>
                            </tr>
                            <tr class="c32">
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c13">Social Network Data</span><span class="c5">&nbsp;such as email, phone number and user name.</span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Providing, Customizing and Improving the Services</span></p>
                                    <p class="c3 c31"><span class="c5"></span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Service Providers</span></p>
                                </td>
                            </tr>
                            <tr class="c32">
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c13">Geolocation Data</span><span class="c5">&nbsp;such as IP-address-based location information.</span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Providing, Customizing and Improving the Services</span></p>
                                    <p class="c3"><span class="c5">-Marketing the Services</span></p>
                                    <p class="c3 c31"><span class="c5"></span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Service Providers</span></p>
                                    <p class="c3"><span class="c5">-Advertising Partners</span></p>
                                </td>
                            </tr>
                            <tr class="c32">
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c13">Sensory Data</span><span class="c5">&nbsp;such as photos, videos, or recordings of you and/or of your environment.</span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Providing, Customizing and Improving the Services</span></p>
                                    <p class="c3 c31"><span class="c5"></span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Service Providers</span></p>
                                </td>
                            </tr>
                            <tr class="c32">
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c13">Inferences Drawn From Other Personal Data Collected </span><span class="c5">such as attributes, user behavior, and predispositions.</span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Providing, Customizing and Improving the Services</span></p>
                                    <p class="c3"><span class="c5">-Marketing the Services</span></p>
                                    <p class="c3 c31"><span class="c5"></span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Service Providers</span></p>
                                </td>
                            </tr>
                            <tr class="c32">
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c13">Other Identifying Information that You Voluntarily Choose to Provide </span><span class="c16">such as emails, letters, texts, or other communications you send us.</span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Providing, Customizing and Improving the Services</span></p>
                                    <p class="c3"><span class="c5">-Marketing the Services</span></p>
                                    <p class="c3"><span class="c5">-Corresponding with You</span></p>
                                </td>
                                <td class="c4" colspan="1" rowspan="1">
                                    <p class="c3"><span class="c5">-Service Providers</span></p>
                                </td>
                            </tr>
                        </table>
                        <p class="c45 c33"><span class="c12"></span></p>
                        <h3 class="c9 c18" id="h.w6s63ed86cj0"><span>Our Commercial or Business Purposes for Collecting Personal Data</span></h3>
                        <ul class="c0 lst-kix_list_3-0 start">
                            <li class="c2 li-bullet-0"><span class="c19">Providing, Customizing and Improving the Services</span></li>
                        </ul>
                        <ul class="c0 lst-kix_list_3-1 start">
                            <li class="c14 li-bullet-0"><span class="c1">Creating and managing your account or other user profiles.</span></li>
                            <li class="c14 li-bullet-0"><span class="c1">Providing you with the products, services or information you request.</span></li>
                            <li class="c14 li-bullet-0"><span class="c1">Meeting or fulfilling the reason you provided the information to us.</span></li>
                            <li class="c14 li-bullet-0"><span class="c1">Providing support and assistance for the Services.</span></li>
                            <li class="c14 li-bullet-0"><span class="c1">Improving the Services, including testing, research, internal analytics and product development.</span></li>
                            <li class="c14 li-bullet-0"><span class="c1">Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth in applicable data privacy laws.</span></li>
                        </ul>
                        <ul class="c0 lst-kix_list_3-0">
                            <li class="c2 li-bullet-0"><span class="c19">Marketing the Services</span></li>
                        </ul>
                        <ul class="c0 lst-kix_list_3-1 start">
                            <li class="c14 li-bullet-0"><span class="c1">Marketing and selling the Services.</span></li>
                        </ul>
                        <ul class="c0 lst-kix_list_3-0">
                            <li class="c2 li-bullet-0"><span class="c19">Corresponding with You</span></li>
                        </ul>
                        <ul class="c0 lst-kix_list_3-1 start">
                            <li class="c14 li-bullet-0"><span class="c1">Responding to correspondence that we receive from you, contacting you when necessary or requested, and sending you information about ChatBCC or the Services.</span></li>
                            <li class="c14 li-bullet-0"><span class="c1">Sending emails and other communications according to your preferences.</span></li>
                        </ul>
                        <h3 class="c36 c18" id="h.ax0sfyj7j70i"><span class="c15">Other Permitted Purposes for Processing Personal Data</span></h3>
                        <p class="c9"><span class="c1">In addition, each of the above referenced categories of Personal Data may be collected, used, and disclosed with the government, including law enforcement, or other parties to meet certain legal requirements and enforcing legal terms including: fulfilling our legal obligations under applicable law, regulation, court order or other legal process, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities; protecting the rights, property or safety of you, ChatBCC or another party; enforcing any agreements with you; responding to claims that any posting or other content violates third-party rights; and resolving disputes.</span></p>
                        <p
                        class="c24"><span class="c1">We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated or incompatible purposes without providing you notice or obtaining your consent.</span></p>
                            <h3
                            class="c9 c18" id="h.qcdockvh0lur"><span class="c15">Categories of Sources of Personal Data</span></h3>
                                <p class="c9"><span class="c1">We collect Personal Data about you from the following categories of sources:</span></p>
                                <ul class="c0 lst-kix_list_3-0">
                                    <li class="c2 li-bullet-0"><span class="c19">You</span></li>
                                </ul>
                                <ul class="c0 lst-kix_list_3-1 start">
                                    <li class="c6 li-bullet-0"><span class="c34">When you provide such information directly to us</span><span class="c1">.</span></li>
                                </ul>
                                <ul class="c0 lst-kix_list_3-2 start">
                                    <li class="c39 li-bullet-0"><span class="c1">When you create an account or use our interactive tools and Services.</span></li>
                                    <li class="c39 li-bullet-0"><span class="c1">When you voluntarily provide information in free-form text boxes through the Services or through responses to surveys or questionnaires.</span></li>
                                    <li class="c39 li-bullet-0"><span class="c1">When you send us an email or otherwise contact us.</span></li>
                                </ul>
                                <ul class="c0 lst-kix_list_3-1">
                                    <li class="c6 li-bullet-0"><span class="c34">When you use the Services and such information is collected automatically</span><span class="c1">.</span></li>
                                </ul>
                                <ul class="c0 lst-kix_list_3-2 start">
                                    <li class="c39 li-bullet-0"><span class="c1">Through Cookies (defined in the &ldquo;Tracking Tools, Advertising and Opt-Out&rdquo; section below).</span></li>
                                    <li class="c39 li-bullet-0"><span>If you download our mobile application or use a location-enabled browser, we may receive information about your location</span><span class="c51">&nbsp;</span><span class="c1">and mobile device, as applicable.</span></li>
                                    <li
                                    class="c39 li-bullet-0"><span class="c1">If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such as information regarding when you are logged on and available to receive updates or alert notices.</span></li>
                                </ul>
                                <ul class="c0 lst-kix_list_3-0">
                                    <li class="c2 li-bullet-0"><span class="c19">Third Parties</span></li>
                                </ul>
                                <ul class="c0 lst-kix_list_3-1 start">
                                    <li class="c14 li-bullet-0"><span class="c10">Vendors</span></li>
                                </ul>
                                <ul class="c0 lst-kix_list_3-2 start">
                                    <li class="c39 li-bullet-0"><span class="c1">We may use analytics providers to analyze how you interact and engage with the Services, or third parties may help us provide you with customer support.</span></li>
                                </ul>
                                <ul class="c0 lst-kix_list_3-1">
                                    <li class="c6 li-bullet-0"><span class="c10">Advertising Partners</span></li>
                                </ul>
                                <ul class="c0 lst-kix_list_3-2 start">
                                    <li class="c39 li-bullet-0"><span class="c1">We receive information about you from some of our vendors who assist us with marketing or promotional services related to how you interact with our websites, applications, products, Services, advertisements or communications.</span></li>
                                </ul>
                                <p class="c45 c33 c52"><span class="c10"></span></p>
                                <h2 class="c18 c24" id="h.wx7u6m5734yr"><span>How We Disclose Your Personal Data</span></h2>
                                <p class="c9"><span class="c1">We disclose your Personal Data to the categories of service providers and other parties listed in this section. Depending on state laws that may be applicable to you, some of these disclosures may constitute a &ldquo;sale&rdquo; of your Personal Data. For more information, please refer to the state-specific sections below. For clarity, in connection with your use of the Services, you may disclose your name, username, bio, social media profile links and other information based on your Account settings. Your disclosure of such Personal Data is considered a &ldquo;User Submission&rdquo; as defined under the Terms of Use, and you expressly grant licenses to chatBCC, as well as other users, to view such User Submission on the Services as further described in the &ldquo;Licenses&rdquo; section in the Terms of Use. </span></p>
                                <ul
                                class="c0 lst-kix_list_4-0 start">
                                    <li class="c30 c18 li-bullet-0"><span class="c28">Service Providers.</span><span>&nbsp;These parties help us provide the Services or perform business functions on our behalf. They include:</span></li>
                                    </ul>
                                    <ul class="c0 lst-kix_list_4-1 start">
                                        <li class="c45 c29 li-bullet-0"><span class="c1">Hosting, technology and communication providers.</span></li>
                                        <li class="c29 c45 li-bullet-0"><span class="c1">Analytics providers for web traffic or usage of the site.</span></li>
                                        <li class="c3 c29 li-bullet-0"><span class="c1">Security and fraud prevention consultants.</span></li>
                                        <li class="c3 c29 li-bullet-0"><span class="c1">Support and customer service vendors.</span></li>
                                        <li class="c3 c29 li-bullet-0"><span class="c1">Product fulfillment and delivery providers.</span></li>
                                        <li class="c3 c29 li-bullet-0"><span class="c1">Payment Processors.</span></li>
                                    </ul>
                                    <ul class="c0 lst-kix_list_4-2 start">
                                        <li class="c3 c7 li-bullet-0"><span class="c1">Our payment processing partners Apple Pay and Google Pay, which collect your voluntarily-provided payment card information necessary to process your payment.</span></li>
                                        <li class="c3 c7 li-bullet-0"><span class="c1">Please see the Payment Processor&rsquo;s terms of service and privacy policy for information on its use and storage of your Personal Data.</span></li>
                                    </ul>
                                    <ul class="c0 lst-kix_list_4-0">
                                        <li class="c3 c17 li-bullet-0"><span class="c28">Advertising Partners. </span><span>These parties help us market our services and provide you with other offers that may be of interest to you. They include:</span></li>
                                    </ul>
                                    <ul class="c0 lst-kix_list_4-1 start">
                                        <li class="c3 c29 li-bullet-0"><span class="c1">Ad networks.</span></li>
                                    </ul>
                                    <p class="c45 c33"><span class="c19"></span></p>
                                    <p class="c36 c18"><span class="c15">Legal Obligations</span></p>
                                    <p class="c45"><span class="c1">We may disclose any Personal Data that we collect with third parties in conjunction with any of the activities set forth under &ldquo;Other Permitted Purposes for Processing Personal Data&rdquo; section above.</span></p>
                                    <p
                                    class="c36 c18"><span class="c15">Business Transfers</span></p>
                                        <p class="c45"><span class="c1">All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part).</span></p>
                                        <p
                                        class="c36 c18"><span class="c15">Data that is Not Personal Data</span></p>
                                            <p class="c9"><span class="c1">We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by removing information that makes the data personally identifiable to a particular user. We may use such aggregated, de-identified or anonymized data and disclose it with third parties for our lawful business purposes, including to analyze, build and improve the Services and promote our business, provided that we will not disclose such data in a manner that could identify you.</span></p>
                                            <h2
                                            class="c9 c18" id="h.u1vcux1tfcp7"><span class="c12">Tracking Tools, Advertising, and Opt-Out</span></h2>
                                                <p class="c9"><span class="c1">The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs and JavaScript (collectively, &ldquo;Cookies&rdquo;) to enable our servers to recognize your web browser, tell us how and when you visit and use our Services, analyze trends, learn about our user base and operate and improve our Services. Cookies are small pieces of data&ndash; usually text files &ndash; placed on your computer, tablet, phone or similar device when you use that device to access our Services. We may also supplement the information we collect from you with information received from third parties, including third parties that have placed their own Cookies on your device(s).</span></p>
                                                <p
                                                class="c9 c18"><span class="c1">We use the following types of Cookies:</span></p>
                                                    <ul class="c0 lst-kix_list_2-0 start">
                                                        <li class="c45 c48 li-bullet-0"><span class="c34">Essential Cookies</span><span class="c1">. Essential Cookies are required for providing you with features or services that you have requested. For example, certain Cookies enable you to log into secure areas of our Services. Disabling these Cookies may make certain features and services unavailable.</span></li>
                                                        <li
                                                        class="c45 c48 li-bullet-0"><span class="c10">Functional Cookies</span><span class="c1">. Functional Cookies are used to record your choices and settings regarding our Services, maintain your preferences over time and recognize you when you return to our Services. These Cookies help us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</span></li>
                                                            <li
                                                            class="c45 c48 li-bullet-0"><span class="c34">Performance/Analytical Cookies</span><span>. Performance/Analytical Cookies allow us to understand how visitors use our Services. They do this by collecting information about the number of visitors to the Services, what pages visitors view on our Services and how long visitors are viewing pages on the Services. Performance/Analytical Cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the Services&rsquo; content for those who engage with our advertising. </span><span>For example, Google LLC (&ldquo;Google&rdquo;) uses cookies in connection with its Google Analytics services. Google&rsquo;s ability to use and disclose information collected by Google Analytics about your visits to the Services is subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt-out of Google&rsquo;s use of Cookies by visiting the Google advertising opt-out page at www.google.com/privacy_ads.html or the Google Analytics Opt-out Browser Add-on at https://tools.google.com/dlpage/gaoptout/.</span></li>
                                                    </ul>
                                                    <ul class="c0 lst-kix_list_29-0 start">
                                                        <li class="c47 c48 li-bullet-0"><span class="c10">Retargeting/Advertising Cookies</span><span class="c1">. Retargeting/Advertising Cookies collect data about your online activity and identify your interests so that we can provide advertising that we believe is relevant to you. For more information about this, please see the section below titled &ldquo;Information about Interest-Based Advertisements.&rdquo;</span></li>
                                                    </ul>
                                                    <p class="c36"><span class="c1">You can decide whether or not to accept Cookies through your internet browser&rsquo;s settings. Most browsers have an option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on your device. If you do this, however, you may have to manually adjust some preferences every time you visit our website and some of the Services and functionalities may not work.</span></p>
                                                    <p
                                                    class="c9"><span>To explore what Cookie settings are available to you or to modify your preferences with respect to Cookies, you can access your Cookie management settings by clicking [</span><span class="c37">LINK</span><span>]. To find out more information about Cookies generally, including information about how to manage and delete Cookies, please visit </span><span><a class="c22" href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</a></span>
                                                        <span
                                                        class="c1">.</span>
                                                            </p>
                                                            <p class="c9 c18"><span class="c34 c50">Information about Interest-Based Advertisements</span></p>
                                                            <p class="c9"><span class="c1">We may serve advertisements, and also allow third-party ad networks, including third-party ad servers, ad agencies, ad technology vendors and research firms, to serve advertisements through the Services. These advertisements may be targeted to users who fit certain general profile categories or display certain preferences or behaviors (&ldquo;Interest-Based Ads&rdquo;). Information for Interest-Based Ads (including Personal Data) may be provided to us by you, or derived from the usage patterns of particular users on the Services and/or services of third parties. Such information may be gathered through tracking users&rsquo; activities across time and unaffiliated properties, including when you leave the Services. To accomplish this, we or our service providers may deliver Cookies, including a file (known as a &ldquo;web beacon&rdquo;) from an ad network to you through the Services. Web beacons allow ad networks to provide anonymized, aggregated auditing, research and reporting for us and for advertisers. Web beacons also enable ad networks to serve targeted advertisements to you when you visit other websites. Web beacons allow ad networks to view, edit or set their own Cookies on your browser, just as if you had requested a web page from their site.</span></p>
                                                            <p
                                                            class="c9"><span>We comply with the Digital Advertising Alliance (&ldquo;DAA&rdquo;) Self-Regulatory Principles for Online Behavioral Advertising. Through the DAA and Network Advertising Initiative (&ldquo;NAI&rdquo;), several media and marketing associations have developed an industry self-regulatory program to give consumers a better understanding of, and greater control over, ads that are customized based on a consumer&rsquo;s online behavior across different websites and properties. To make choices about Interest-Based Ads from participating third parties, including to opt-out of receiving behaviorally targeted advertisements from participating organizations, please visit the DAA&rsquo;s or NAI&rsquo;s consumer opt-out pages, which are located at </span><span class="c23"><a class="c22" href="http://www.networkadvertising.org/choices/">networkadvertising.org</a></span><span>&nbsp;or </span><span class="c23"><a class="c22" href="http://www.aboutads.info/choices">aboutads.info</a></span>
                                                                <span
                                                                class="c1">.</span>
                                                                            </p>
                                                                            <h2 class="c9 c18" id="h.haf8z3yb8qkw"><span class="c12">Data Security</span></h2>
                                                                            <p class="c9"><span class="c1">We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical, organizational and administrative security measures based on the type of Personal Data and how we are processing that data. You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to protect the security of your account and other data that we hold in our records, please be aware that no method of transmitting data over the internet or storing data is completely secure.</span></p>
                                                                            <h2
                                                                            class="c9 c18" id="h.e4omy61mhttz"><span class="c12">Data Retention</span></h2>
                                                                                <p class="c9"><span>We retain Personal Data about you for as long as necessary to provide you with our Services or to perform our business or commercial purposes for collecting your Personal Data. When establishing a retention period for specific categories of data, we consider who we collected the data from, our need for the Personal Data, why we collected the Personal Data, and the sensitivity of the Personal Data. In some cases we retain Personal Data for longer, if doing so is necessary </span>
                                                                                    <span
                                                                                    class="c41">to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. W</span>
                                                                                        <span
                                                                                        class="c1">e may further retain information in an anonymous or aggregated form where that information would not identify you personally.</span>
                                                                                </p>
                                                                                <p class="c9 c18"><span>For example:</span></p>
                                                                                <ul class="c0 lst-kix_list_11-0 start">
                                                                                    <li class="c30 li-bullet-0"><span class="c1">We retain your profile information and credentials for as long as you have an account with us.</span></li>
                                                                                    <li class="c9 c40 li-bullet-0"><span class="c1">We retain your device/IP data for as long as we need it to ensure that our systems are working appropriately, effectively and efficiently.</span></li>
                                                                                </ul>
                                                                                <h2 class="c9 c18" id="h.hw0ypqccnog9"><span class="c12">Personal Data of Children</span></h2>
                                                                                <p class="c24"><span>As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data from children under 16 years of age; if you are a child under the age of 16, please do not attempt to register for or otherwise use the Services or send us any Personal Data. If we learn we have collected Personal Data from a child under 16 years of age, we will delete that information as quickly as possible. If you believe that a child under 16 years of age may have provided Personal Data to us, please contact us at </span>
                                                                                    <span
                                                                                    class="c23"><a class="c22" href="mailto:support@chatbcc.com">support@chatbcc.com</a></span><span class="c1">. </span></p>
                                                                                <h2 class="c24 c18" id="h.vzykhp1rgvtl"><span class="c12">Other State Law Privacy Rights</span></h2>
                                                                                <p class="c9 c18"><span class="c15">California Resident Rights</span></p>
                                                                                <p class="c9"><span>Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data to third parties for such third parties&rsquo; direct marketing purposes; in order to submit such a request, please contact us at </span>
                                                                                    <span
                                                                                    class="c23"><a class="c22" href="mailto:support@chatbcc.com">support@chatbcc.com</a></span><span class="c1">. </span></p>
                                                                                <p class="c9 c18"><span class="c15">Nevada Resident Rights</span></p>
                                                                                <p class="c9"><span class="c1">Please note that we do not currently sell your Personal Data as sales are defined in Nevada Revised Statutes Chapter 603A.</span></p>
                                                                                <h2 class="c24 c18" id="h.dzwlxfar7jus"><span class="c12">Contact Information</span></h2>
                                                                                <p class="c24"><span class="c1">If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact us at:</span></p>
                                                                                <ul
                                                                                class="c0 lst-kix_list_7-0 start">
                                                                                    <li class="c30 li-bullet-0"><span class="c23"><a class="c22" href="https://chatbcc.com">chatbcc.com</a></span></li>
                                                                                    <li
                                                                                    class="c30 li-bullet-0"><span class="c23"><a class="c22" href="mailto:support@chatbcc.com">support@chatbcc.com</a></span><span class="c1">&nbsp;</span></li>
                                                                                        <li class="c9 c40 li-bullet-0"><span class="c1">548 Market St PMB 478800, San Francisco, California &nbsp;94104-5401</span></li>
                                                                                        </ul>
`

export const PrivacyScreen = () => {
  return (
    <section className="PrivacyContent light-mode">
      <div className="mx-auto max-w-3xl overflow-x-auto px-4 py-8">
        <div dangerouslySetInnerHTML={{ __html: privacyContent }} />
      </div>
    </section>
  )
}
