import "./terms.css"

// Link to doc: https://docs.google.com/document/d/1RgByoCoxaf35etl7BTnZjA9SL-PBPoz9CofD1Lg-7KQ/edit?usp=sharing
// Export as HTML and then beautify with: https://www.site24x7.com/tools/html-beautifier.html
// Styled with CSS Nesting: https://medium.com/@rapPayne/stop-writing-react-local-styles-use-the-component-classname-pattern-9a820c6447de

export const termsContent = `
    <div>
        <p class="c27"><span class="c23"></span></p>
    </div>
    <p class="c28"><span class="c14 c12">Terms of Use</span></p>
    <p class="c25"><span class="c2">Effective date: January 17, 2025</span></p>
    <p class="c11"><span class="c2">Welcome to chatBCC. Please read on to learn the rules and restrictions that govern your use of our website(s), products, services and applications (the &ldquo;Services&rdquo;). If you have any questions, comments, or concerns regarding these terms or the Services, please contact us at:</span></p>
    <p
    class="c1"><span class="c13">Email: &nbsp;</span><span class="c9"><a class="c8" href="mailto:support@chatbcc.com">support@chatbcc.com</a></span></p>
        <p class="c11"><span class="c2">Address: &nbsp;548 Market St PMB 478800, San Francisco, California &nbsp;94104-5401</span></p>
        <p class="c11"><span class="c13">These Terms of Use (the &ldquo;Terms&rdquo;) are a binding contract between you and </span><span class="c12">CHATBCC, INC.</span><span class="c13">&nbsp;(&ldquo;chatBCC,&rdquo; &ldquo;we&rdquo; and &ldquo;us&rdquo;). Your use of the Services in any way means that you agree to all of these Terms, and these Terms will remain in effect while you use the Services. These Terms include the provisions in this document as well as those in the </span>
            <span
            class="c9"><a class="c8" href="https://chatbcc.com/privacy">Privacy Policy</a></span><span class="c13">&nbsp;and Copyright Dispute Policy in the &ldquo;</span>
                <span
                class="c9"><a class="c8" href="#h.30j0zll">What if I see something on the Services that infringes my copyright?</a></span><span class="c13">&rdquo; section of these Terms.</span><span class="c3">&nbsp;Your use of or participation in certain Services may also be subject to additional policies, rules and/or conditions (&ldquo;Additional Terms&rdquo;), which are incorporated herein by reference, and you understand and agree that by using or participating in any such Services, you agree to also comply with these Additional Terms. </span></p>
        <p
        class="c11"><span class="c12">Please read these Terms carefully. </span><span class="c13">They cover important information about Services provided to you. </span><span class="c12">These Terms include information about </span><span class="c9 c19"><a class="c8" href="#h.gjdgxs">future changes to these Terms</a></span>
            <span
            class="c12">, </span><span class="c9 c19"><a class="c8" href="#id.2et92p0">limitations of liability</a></span><span class="c12">, </span><span class="c9 c19"><a class="c8" href="#id.3dy6vkm">a class action waiver and resolution of disputes by arbitration instead of in court</a></span>
                <span
                class="c3">. PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.</span>
                    </p>
                    <p class="c11"><span class="c12">ARBITRATION NOTICE AND CLASS ACTION WAIVER:</span><span class="c13">&nbsp;EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE </span><span class="c9"><a class="c8" href="#id.3dy6vkm">ARBITRATION AGREEMENT SECTION BELOW</a></span>
                        <span
                        class="c13">, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.</span>
                    </p>
                    <h1 class="c10" id="h.gjdgxs"><span class="c14 c12">Will these Terms ever change?</span></h1>
                    <p class="c11"><span class="c2">We are constantly trying to improve our Services, so these Terms may need to change along with our Services. We reserve the right to change the Terms at any time, but if we do, we will place a notice on our site located at chatbcc.com, send you an email, and/or notify you by some other means. </span></p>
                    <p
                    class="c11"><span class="c2">If you don&rsquo;t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to all of the changes. </span></p>
                        <p
                        class="c11"><span class="c2">Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us. </span></p>
                            <h1 class="c10"><span class="c14 c12">What about my privacy?</span></h1>
                            <p class="c11"><span class="c13">chatBCC takes the privacy of its users very seriously. For the current chatBCC Privacy Policy, please click </span><span class="c9"><a class="c8" href="https://chatbcc.com/privacy">here</a></span>
                                <span
                                class="c2">.</span>
                            </p>
                            <p class="c11"><span class="c0 c18">Children&rsquo;s Online Privacy Protection Act</span></p>
                            <p class="c11"><span class="c13">The Children&rsquo;s Online Privacy Protection Act (&ldquo;COPPA&rdquo;) requires that online service providers obtain parental consent before they knowingly collect personally identifiable information online from children who are under 13 years of age. We do not knowingly collect or solicit personally identifiable information from children under </span><span>16 </span>
                                <span
                                class="c13">years of age; if you are a child under 16 years of age, please do not attempt to register for or otherwise use the Services or send us any personal information. If we learn we have collected personal information from a
                                    child under 16 years of age, we will delete that information as quickly as possible. If you believe that a child under 16 years of age may have provided us personal information, please contact us at </span><span class="c9"><a class="c8" href="mailto:support@chatbcc.com">support@chatbcc.com</a></span>
                                    <span
                                    class="c2">.</span>
                            </p>
                            <p class="c11"><span class="c18 c0">Prohibition of Child Sexual Exploitation and Abuse (&ldquo;CSEA&rdquo;)</span></p>
                            <p class="c11"><span class="c2">chatBCC has a zero-tolerance policy for any form of child sexual exploitation and abuse (&ldquo;CSEA&rdquo;). CSEA includes content, behavior or activities that exploit or harm individuals under 18 years old, such as grooming, trafficking, exploitation and child sexual abuse material.</span></p>
                            <p
                            class="c11"><span class="c13">We prohibit all CSEA-related activities while using the Services, as well as any User Submissions (as defined below) that include CSEA materials. This prohibition includes uploading or attempting to upload User Submissions that promote or depict CSEA, attempting to use the Services to groom, traffick or exploit individuals under 18 years old, and participating, encouraging or aiding others in CSEA-related activities. We reserve the right to monitor use of the Services and any User Submissions to prevent CSEA-related User Submissions and activities while using the Services. If you see any CSEA-related User Submissions while using chatBCC, please report the user and the applicable User Submission to </span>
                                <span
                                class="c9"><a class="c8" href="mailto:support@chatbcc.com">support@chatbcc.com</a></span><span class="c13 c29">. </span><span class="c13 c15">chatBCC reserves the right to immediately remove any User Submission that includes CSEA-related materials or that directly or indirectly promotes CSEA</span>
                                    <span
                                    class="c2">&nbsp;in any way, and to terminate the user who posted such User Submission or engaged in any CSEA-related activities. Posting, re-posting or otherwise engaging in any way with CSEA-related User Submissions and activities
                                        is a criminal offense, and we will notify law enforcement and child protection agencies, as required under applicable laws, if we suspect any such activities on the chatBCC platform.</span>
                                        </p>
                                        <h1 class="c10"><span class="c14 c12">What are the basics of using chatBCC?</span></h1>
                                        <p class="c11"><span class="c2">You may be required to sign up for an account, select a password and user name (&ldquo;chatBCC User ID&rdquo;), and provide us with certain information or data, such as your contact information. You promise to provide us with accurate, complete, and updated registration information about yourself. You may not select as your chatBCC User ID a name that you do not have the right to use, or another person&rsquo;s name with the intent to impersonate that person. You may not transfer your account to anyone else without our prior written permission.</span></p>
                                        <p
                                        class="c11"><span class="c2">You represent and warrant that you are an individual of legal age to form a binding contract (or if not, you&rsquo;ve received your parent&rsquo;s or guardian&rsquo;s permission to use the Services and have gotten your parent or guardian to agree to these Terms on your behalf).</span></p>
                                            <p
                                            class="c11"><span class="c2">You will only use the Services for your own internal, personal use, and not on behalf of or for the benefit of any third party, and only in a manner that complies with all laws that apply to you. If your use of the Services is prohibited by applicable laws, then you aren&rsquo;t authorized to use the Services. We can&rsquo;t and won&rsquo;t be responsible for your using the Services in a way that breaks the law.</span></p>
                                                <p
                                                class="c11"><span class="c2">You will not share your chatBCC User ID, account or password with anyone, and you must protect the security of your chatBCC User ID, account, password and any other access tools or credentials. You&rsquo;re responsible for any activity associated with your chatBCC User ID and account.</span></p>
                                                    <h1
                                                    class="c10"><span class="c14 c12">What about messaging?</span></h1>
                                                        <p class="c11"><span class="c2">As part of the Services, you may receive communications through the Services, including messages that chatBCC sends you (for example, via email or SMS). When signing up for the Services, you will receive a welcome message and instructions on how to stop receiving messages. </span>
                                                            <span
                                                            class="c3">By signing up for the Services and providing us with your wireless number, you confirm that you want chatBCC to send you information regarding your account (including if you get off any chatBCC
                                                                waitlist) or transactions with us, which may include chatBCC using automated dialing technology to text you at the wireless number you provided, and you agree to receive communications from
                                                                chatBCC, and you represent and warrant that each person you register for the Services or for whom you provide a wireless phone number has consented to receive communications from chatBCC.</span>
                                                                <span
                                                                class="c2">&nbsp;You agree to indemnify and hold chatBCC harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys&rsquo;
                                                                    fees) arising from or in any way related to your breach of the foregoing.</span>
                                                        </p>
                                                        <h1 class="c10"><span class="c14 c12">Are there restrictions in how I can use the Services?</span></h1>
                                                        <p class="c11"><span class="c2">You represent, warrant, and agree that you will not provide or contribute anything, including any Content or User Submission (as those terms are defined below), to the Services, or otherwise use or interact with the Services, in a manner that:</span></p>
                                                        <ol
                                                        class="c16 lst-kix_list_2-0 start" start="1">
                                                            <li class="c1 c5 li-bullet-0"><span class="c2">infringes or violates the intellectual property rights or any other rights of anyone else (including chatBCC);</span></li>
                                                            <li class="c1 c5 li-bullet-0"><span class="c2">violates any law or regulation, including, without limitation, any applicable export control laws, privacy laws or any other purpose not reasonably intended by chatBCC;</span></li>
                                                            <li
                                                            class="c1 c5 li-bullet-0"><span class="c2">is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</span></li>
                                                                <li class="c1 c5 li-bullet-0"><span class="c2">jeopardizes the security of your chatBCC User ID, account or anyone else&rsquo;s (such as allowing someone else to log in to the Services as you);</span></li>
                                                                <li class="c1 c5 li-bullet-0"><span class="c2">attempts, in any manner, to obtain the password, account, or other security information from any other user;</span></li>
                                                                <li class="c1 c5 li-bullet-0"><span class="c2">violates the security of any computer network, or cracks any passwords or security encryption codes;</span></li>
                                                                <li class="c1 c5 li-bullet-0"><span class="c2">runs Maillist, Listserv, any form of auto-responder or &ldquo;spam&rdquo; on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services&rsquo; infrastructure);</span></li>
                                                                <li
                                                                class="c1 c5 li-bullet-0"><span class="c2">&ldquo;crawls,&rdquo; &ldquo;scrapes,&rdquo; or &ldquo;spiders&rdquo; any page, data, or portion of or relating to the Services or Content (through use of manual or automated means);</span></li>
                                                                    <li
                                                                    class="c1 c5 li-bullet-0"><span class="c2">copies or stores any significant portion of the Content; or</span></li>
                                                                        <li class="c1 c5 li-bullet-0"><span class="c2">decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services.</span></li>
                                                                        </ol>
                                                                        <p class="c1 c22"><span class="c2"></span></p>
                                                                        <p class="c11"><span class="c2">A violation of any of the foregoing is grounds for termination of your right to use or access the Services. </span></p>
                                                                        <h1 class="c10"><span class="c12 c14">What are my rights in the Services?</span></h1>
                                                                        <p class="c11"><span class="c2">The materials displayed or performed or available on or through the Services, including, but not limited to, text, graphics, data, articles, photos, images, illustrations, User Submissions (as defined below) and so forth (all of the foregoing, the &ldquo;Content&rdquo;) are protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Services, and you won&rsquo;t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else&rsquo;s (including chatBCC&#39;s) rights.</span></p>
                                                                        <p
                                                                        class="c11"><span class="c2">Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using the Services. Use, reproduction, modification, distribution or storage of any Content for any purpose other than using the Services is expressly prohibited without prior written permission from us. You understand that chatBCC owns the Services. You won&rsquo;t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Services. The Services may allow you to copy or download certain Content, but please remember that even where these functionalities exist, all the restrictions in this section still apply.</span></p>
                                                                            <h1
                                                                            class="c26"><span class="c14 c12">What about anything I contribute to the Services &ndash; do I have to grant any licenses to chatBCC or to other users?</span></h1>
                                                                                <p class="c11"><span class="c0 c4">User Submissions</span></p>
                                                                                <p class="c11"><span class="c2">Anything you post, upload, share, store, or otherwise provide through the Services is your &ldquo;User Submission&rdquo;. Some User Submissions may be viewable by other users. You are solely responsible for all User Submissions you contribute to the Services. You represent that all User Submissions submitted by you are accurate, complete, up-to-date, and in compliance with all applicable laws, rules and regulations.</span></p>
                                                                                <p
                                                                                class="c11"><span class="c2">You agree that you will not post, upload, share, store, or otherwise provide through the Services any User Submissions that: (i) infringe any third party&#39;s copyrights or other rights (e.g., trademark, privacy rights, etc.); (ii) contain sexually explicit content or pornography; (iii) contain hateful, defamatory, or discriminatory content or incite hatred against any individual or group; (iv) exploit minors; (v) depict unlawful acts or extreme violence; (vi) depict animal cruelty or extreme violence towards animals; (vii) promote fraudulent schemes, multi-level marketing (MLM) schemes, get rich quick schemes, online gaming and gambling, cash gifting, work from home businesses, or any other dubious money-making ventures; or (viii) that violate any law.</span></p>
                                                                                    <p
                                                                                    class="c11"><span class="c4 c0">Licenses</span></p>
                                                                                        <p class="c11"><span class="c13">In order to display your User Submissions on the Services, and to allow other users to enjoy them (where applicable), you grant us certain rights in those User Submissions (see below for more information). Please note that all of the following licenses are subject to our </span>
                                                                                            <span
                                                                                            class="c9"><a class="c8" href="https://chatbcc.com/privacy">Privacy Policy</a></span>
                                                                                                <span
                                                                                                class="c2">&nbsp;to the extent they relate to User Submissions that are also your personally-identifiable information.</span>
                                                                                        </p>
                                                                                        <p class="c11"><span class="c2">For all User Submissions, you hereby grant chatBCC a license to translate, modify (for technical purposes, for example, making sure your content is viewable on a mobile device as well as a computer) and reproduce and otherwise act with respect to such User Submissions, in each case to enable us to operate the Services, as described in more detail below. This is a license only &ndash; your ownership in User Submissions is not affected.</span></p>
                                                                                        <p
                                                                                        class="c11"><span class="c2">If you share a User Submission in a manner that only certain specified users can view (for example, only subscribers can see certain User Submissions) (a &ldquo;Limited Audience User Submission&rdquo;), then you grant chatBCC the licenses above, as well as a license to display, perform, and distribute your Limited Audience User Submission for the sole purpose of making that Limited Audience User Submission accessible to such other specified users, and providing the Services necessary to do so. Also, you grant such other specified users a license to access that Limited Audience User Submission, and to use and exercise all rights in it, as permitted by the functionality of the Services.</span></p>
                                                                                            <p
                                                                                            class="c11"><span class="c2">If you share a User Submission publicly on the Services and/or in a manner that more than just you or certain specified users can view, or if you provide us (in a direct email or otherwise) with any feedback, suggestions, improvements, enhancements, and/or feature requests relating to the Services (each of the foregoing, a &ldquo;Public User Submission&rdquo;), then you grant chatBCC the licenses above, as well as a license to display, perform, and distribute your Public User Submission for the purpose of making that Public User Submission accessible to all chatBCC users and providing the Services necessary to do so, as well as all other rights necessary to use and exercise all rights in that Public User Submission in connection with the Services and/or otherwise in connection with chatBCC&rsquo;s business. Also, you grant all other users of the Services a license to access that Public User Submission, and to use and exercise all rights in it, as permitted by the functionality of the Services.</span></p>
                                                                                                <p
                                                                                                class="c11"><span class="c2">You agree that the licenses you grant are royalty-free, perpetual, sublicensable, irrevocable, and worldwide, provided that when you delete your chatBCC account, we will stop displaying your User Submissions (other than Limited Audience User Submissions and Public User Submissions, which may remain fully available) to other users (if applicable), but you understand and agree that it may not be possible to completely delete that content from chatBCC&rsquo;s records, and that your User Submissions may remain viewable elsewhere to the extent that they were copied or stored by other users.</span></p>
                                                                                                    <p
                                                                                                    class="c11"><span class="c2">Certain features of the Services allow you to share information with others, including through your social networks or other services where you have an account (&ldquo;Third Party Accounts&rdquo;). When Content is authorized for sharing, we will clearly identify the Content you are authorized to redistribute and the ways you may redistribute it, usually by providing a &ldquo;share&rdquo; button on or near the Content. If you share information from the Services with others through your Third Party Accounts, such as your social networks, you authorize chatBCC to share that information with the applicable Third Party Account provider. Please review the policies of any Third Party Account providers you share information with or through for additional information about how they may use your information. If you redistribute Content, you must be able to edit or delete any Content you redistribute, and you must edit or delete it promptly upon our request.</span></p>
                                                                                                        <p
                                                                                                        class="c11"><span class="c2">Finally, you understand and agree that chatBCC, in performing the required technical steps to provide the Services to our users (including you), may need to make changes to your User Submissions to conform and adapt those User Submissions to the technical requirements of connection networks, devices, services, or media, and the foregoing licenses include the rights to do so.</span></p>
                                                                                                            <a
                                                                                                            id="id.1fob9te"></a>
                                                                                                                <h1 class="c10" id="h.30j0zll"><span class="c14 c12">What if I see something on the Services that infringes my copyright?</span></h1>
                                                                                                                <p class="c11"><span class="c2">In accordance with the DMCA, we&rsquo;ve adopted the following policy toward copyright infringement. &nbsp;We reserve the right to (1) block access to or remove material that we believe in good faith to be copyrighted material that has been illegally copied and distributed by any of our advertisers, affiliates, content providers, members or users and (2) remove and discontinue service to repeat offenders.</span></p>
                                                                                                                <ol
                                                                                                                class="c16 lst-kix_list_14-0 start" start="1">
                                                                                                                    <li class="c11 c5 li-bullet-0"><span class="c18 c0">Procedure for Reporting Copyright Infringements</span><span class="c2">. &nbsp;If you believe that material or content residing on or accessible through the Services infringes your copyright (or the copyright of someone whom you are authorized to act on behalf of), please send a notice of copyright infringement containing the following information to chatBCC&#39;s Designated Agent to Receive Notification of Claimed Infringement (our &ldquo;Designated Agent,&rdquo; whose contact details are listed below):</span></li>
                                                                                                                    </ol>
                                                                                                                    <ol class="c16 lst-kix_list_15-0 start" start="1">
                                                                                                                        <li class="c1 c6 li-bullet-0"><span class="c2">A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed;</span></li>
                                                                                                                        <li
                                                                                                                        class="c1 c6 li-bullet-0"><span class="c2">Identification of works or materials being infringed;</span></li>
                                                                                                                            <li class="c1 c6 li-bullet-0"><span class="c2">Identification of the material that is claimed to be infringing including information regarding the location of the infringing materials that the copyright owner seeks to have removed, with sufficient detail so that Company is capable of finding and verifying its existence;</span></li>
                                                                                                                            <li
                                                                                                                            class="c1 c6 li-bullet-0"><span class="c2">Contact information about the notifier including address, telephone number and, if available, email address;</span></li>
                                                                                                                                <li
                                                                                                                                class="c1 c6 li-bullet-0"><span class="c2">A statement that the notifier has a good faith belief that the material identified in (1)(c) is not authorized by the copyright owner, its agent, or the law; and</span></li>
                                                                                                                                    <li
                                                                                                                                    class="c11 c6 li-bullet-0"><span class="c2">A statement made under penalty of perjury that the information provided is accurate and the notifying party is authorized to make the complaint on behalf of the copyright owner.</span></li>
                                                                                                                    </ol>
                                                                                                                    <ol class="c16 lst-kix_list_14-0" start="2">
                                                                                                                        <li class="c11 c5 li-bullet-0"><span class="c18 c0">Once Proper Bona Fide Infringement Notification is Received by the Designated Agent</span><span class="c2">. &nbsp;Upon receipt of a proper notice of copyright infringement, we reserve the right to:</span></li>
                                                                                                                    </ol>
                                                                                                                    <ol class="c16 lst-kix_list_16-0 start" start="1">
                                                                                                                        <li class="c1 c6 li-bullet-0"><span class="c2">remove or disable access to the infringing material;</span></li>
                                                                                                                        <li class="c1 c6 li-bullet-0"><span class="c2">notify the content provider who is accused of infringement that we have removed or disabled access to the applicable material; and</span></li>
                                                                                                                        <li
                                                                                                                        class="c11 c6 li-bullet-0"><span class="c2">terminate such content provider&#39;s access to the Services if he or she is a repeat offender.</span></li>
                                                                                                                    </ol>
                                                                                                                    <ol class="c16 lst-kix_list_14-0" start="3">
                                                                                                                        <li class="c11 c5 li-bullet-0"><span class="c18 c0">Procedure to Supply a Counter-Notice to the Designated Agent</span><span class="c2">. &nbsp;If the content provider believes that the material that was removed (or to which access was disabled) is not infringing, or the content provider believes that it has the right to post and use such material from the copyright owner, the copyright owner&#39;s agent, or, pursuant to the law, the content provider may send us a counter-notice containing the following information to the Designated Agent:</span></li>
                                                                                                                    </ol>
                                                                                                                    <ol class="c16 lst-kix_list_17-0 start" start="1">
                                                                                                                        <li class="c1 c20 li-bullet-1"><span class="c2">A physical or electronic signature of the content provider;</span></li>
                                                                                                                        <li class="c1 c20 li-bullet-1"><span class="c2">Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or disabled;</span></li>
                                                                                                                        <li
                                                                                                                        class="c1 c20 li-bullet-2"><span class="c2">A statement that the content provider has a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material; and</span></li>
                                                                                                                            <li
                                                                                                                            class="c11 c20 li-bullet-1"><span class="c2">Content provider&#39;s name, address, telephone number, and, if available, email address, and a statement that such person or entity consents to the jurisdiction of the Federal Court for the judicial district in which the content provider&rsquo;s address is located, or, if the content provider&#39;s address is located outside the United States, for any judicial district in which Company is located, and that such person or entity will accept service of process from the person who provided notification of the alleged infringement.</span></li>
                                                                                                                    </ol>
                                                                                                                    <p class="c11"><span class="c2">If a counter-notice is received by the Designated Agent, Company may, in its discretion, send a copy of the counter-notice to the original complaining party informing that person that Company may replace the removed material or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider accused of committing infringement, the removed material may be replaced or access to it restored in 10 to 14 business days or more after receipt of the counter-notice, at Company&#39;s discretion.</span></p>
                                                                                                                    <p
                                                                                                                    class="c11"><span class="c2">Please contact chatBCC&#39;s Designated Agent at the following address:</span></p>
                                                                                                                        <p class="c1"><span class="c2">chatBCC, Inc.</span></p>
                                                                                                                        <p class="c1"><span class="c2">Attn: DMCA Designated Agent</span></p>
                                                                                                                        <p class="c1"><span class="c2">548 Market St PMB 478800</span></p>
                                                                                                                        <p class="c11"><span class="c2">San Francisco, CA &nbsp;94104-5401</span></p>
                                                                                                                        <h1 class="c10"><span class="c14 c12">Who is responsible for what I see and do on the Services?</span></h1>
                                                                                                                        <p class="c11"><span class="c2">Any information or Content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such Content originated, and you access all such information and Content at your own risk, and we aren&rsquo;t liable for any errors or omissions in that information or Content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and you hereby release us from all liability for you having acquired or not acquired Content through the Services. We can&rsquo;t guarantee the identity of any users with whom you interact in using the Services and are not responsible for which users gain access to the Services. </span></p>
                                                                                                                        <p
                                                                                                                        class="c11"><span class="c2">You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it.</span></p>
                                                                                                                            <p
                                                                                                                            class="c11"><span class="c2 c17">The Services may contain links or connections to third-party websites or services that are not owned or controlled by chatBCC. When you access third-party websites or use third-party services, you accept that there are risks in doing so, and that chatBCC is not responsible for such risks. </span></p>
                                                                                                                                <p
                                                                                                                                class="c11"><span class="c2 c17">chatBCC has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third-party websites or by any third party that you interact with through the Services. In addition, chatBCC will not and cannot monitor, verify, censor or edit the content of any third-party site or service. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each third-party website or service that you visit or utilize. By using the Services, you release and hold us harmless from any and all liability arising from your use of any third-party website or service. </span></p>
                                                                                                                                    <p
                                                                                                                                    class="c11"><span class="c2 c17">Your interactions with organizations and/or individuals found on or through the Services, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties. You agree that chatBCC shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.</span></p>
                                                                                                                                        <p
                                                                                                                                        class="c11"><span class="c2 c17">If there is a dispute between participants on this site or Services, or between users and any third party, you agree that chatBCC is under no obligation to become involved. In the event that you have a dispute with one or more other users, you release chatBCC, its directors, officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services. You shall and hereby do waive California Civil Code Section 1542 or any similar law of any jurisdiction, which says in substance: &ldquo;A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.&rdquo;</span></p>
                                                                                                                                            <h1
                                                                                                                                            class="c10"><span class="c14 c12">Will chatBCC ever change the Services?</span></h1>
                                                                                                                                                <p class="c11"><span class="c2">We&rsquo;re always trying to improve our Services, so they may change over time. We may suspend or discontinue any part of the Services, or we may introduce new features or impose limits on certain features or restrict access to parts or all of the Services. We&rsquo;ll try to give you notice when we make a material change to the Services that would adversely affect you, but this isn&rsquo;t always practical. We reserve the right to remove any Content from the Services at any time, for any reason (including, but not limited to, if someone alleges you contributed that Content in violation of these Terms), in our sole discretion, and without notice.</span></p>
                                                                                                                                                <h1
                                                                                                                                                class="c10" id="h.3znysh7"><span class="c14 c12">Do the Services cost anything?</span></h1>
                                                                                                                                                    <p class="c11"><span class="c2">The Services are currently free, but we reserve the right to charge for certain or all Services in the future. We will notify you before any Services you are then using begin carrying a fee, and if you wish to continue using such Services, you must pay all applicable fees for such Services.</span></p>
                                                                                                                                                    <h1
                                                                                                                                                    class="c10"><span class="c14 c12">What if I want to stop using the Services? </span></h1>
                                                                                                                                                        <p class="c11"><span class="c13">You&rsquo;re free to do that at any time by contacting us at </span><span class="c9"><a class="c8" href="mailto:support@chatbcc.com">support@chatbcc.com</a></span>
                                                                                                                                                            <span
                                                                                                                                                            class="c13">; please refer to our </span><span class="c9"><a class="c8" href="https://chatbcc.com/privacy">Privacy Policy</a></span>
                                                                                                                                                                <span
                                                                                                                                                                class="c2">, as well as the licenses above, to understand how we treat information you provide to us after
                                                                                                                                                                    you have stopped using our Services.</span>
                                                                                                                                                        </p>
                                                                                                                                                        <p class="c11"><span class="c2">chatBCC is also free to terminate (or suspend access to) your use of the Services or your account for any reason in our discretion, including your breach of these Terms. chatBCC has the sole right to decide whether you are in violation of any of the restrictions set forth in these Terms.</span></p>
                                                                                                                                                        <p
                                                                                                                                                        class="c11"><span class="c2">Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account. We will try to provide advance notice to you prior to our terminating your account so that you are able to retrieve any important User Submissions you may have stored in your account (to the extent allowed by law and these Terms), but we may not do so if we determine it would be impractical, illegal, not in the interest of someone&rsquo;s safety or security, or otherwise harmful to the rights or property of chatBCC.</span></p>
                                                                                                                                                            <p
                                                                                                                                                            class="c11"><span class="c13">If you have deleted your account by mistake, contact us immediately at </span>
                                                                                                                                                                <span
                                                                                                                                                                class="c9"><a class="c8" href="mailto:support@chatbcc.com">support@chatbcc.com</a></span><span class="c2">&nbsp;&ndash; we will try to help, but unfortunately, we can&rsquo;t promise that we can recover or restore anything.</span></p>
                                                                                                                                                                    <p
                                                                                                                                                                    class="c11"><span class="c2">Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation you have to indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us, including without limitation the arbitration agreement.</span></p>
                                                                                                                                                                        <h1
                                                                                                                                                                        class="c10"><span class="c14 c12">What about Mobile Applications?</span></h1>
                                                                                                                                                                            <p class="c11"><span class="c2">You acknowledge and agree that the availability of our mobile application is dependent on the third party stores from which you download the application, e.g., the App Store from Apple or the Android app market from Google (each an &ldquo;App Store&rdquo;). Each App Store may have its own terms and conditions to which you must agree before downloading mobile applications from such store, including the specific terms relating to Apple App Store set forth below. You agree to comply with, and your license to use our application is conditioned upon your compliance with, such App Store terms and conditions. To the extent such other terms and conditions from such App Store are less restrictive than, or otherwise conflict with, the terms and conditions of these Terms of Use, the more restrictive or conflicting terms and conditions in these Terms of Use apply.</span></p>
                                                                                                                                                                            <h1
                                                                                                                                                                            class="c10"><span class="c14 c12">I use the chatBCC App available via the Apple App Store &ndash; should I know anything about that?</span></h1>
                                                                                                                                                                                <p
                                                                                                                                                                                class="c11"><span class="c2">These Terms apply to your use of all the Services, including our iOS applications (the &ldquo;Application&rdquo;) available via the Apple, Inc. (&ldquo;Apple&rdquo;) App Store, but the following additional terms also apply to the Application:</span></p>
                                                                                                                                                                                    <ol
                                                                                                                                                                                    class="c16 lst-kix_list_3-0 start" start="1">
                                                                                                                                                                                        <li class="c21 li-bullet-3"><span class="c2">Both you and chatBCC acknowledge that the Terms are concluded between you and chatBCC only, and not with Apple, and that Apple is not responsible for the Application or the Content;</span></li>
                                                                                                                                                                                        <li
                                                                                                                                                                                        class="c21 li-bullet-4"><span class="c2">The Application is licensed to you on a limited, non-exclusive, non-transferrable, non-sublicensable basis, solely to be used in connection with the Services for your private, personal, non-commercial use, subject to all the terms and conditions of these Terms as they are applicable to the Services;</span></li>
                                                                                                                                                                                            <li
                                                                                                                                                                                            class="c21 li-bullet-3"><span class="c2">You will only use the Application in connection with an Apple device that you own or control;</span></li>
                                                                                                                                                                                                <li
                                                                                                                                                                                                class="c21 li-bullet-5"><span class="c2">You acknowledge and agree that Apple has no obligation whatsoever to furnish any </span>
                                                                                                                                                                                                    <span
                                                                                                                                                                                                    class="c2 c17">maintenance</span><span class="c2">&nbsp;and support services with respect to the Application;</span></li>
                                                                                                                                                                                                        <li
                                                                                                                                                                                                        class="c21 li-bullet-5"><span class="c2">&nbsp;In the event of any failure of the Application to conform to any applicable warranty, including those implied by law, you may notify Apple of such failure; upon notification, Apple&rsquo;s sole warranty obligation to you will be to refund to you the purchase price, if any, of the Application;</span></li>
                                                                                                                                                                                                            <li
                                                                                                                                                                                                            class="c21 li-bullet-5"><span class="c2">You acknowledge and agree that chatBCC, and not Apple, is responsible for addressing any </span>
                                                                                                                                                                                                                <span
                                                                                                                                                                                                                class="c2 c17">claims</span><span class="c2">&nbsp;you or any third party may have in relation to the Application;</span></li>
                                                                                                                                                                                                                    <li
                                                                                                                                                                                                                    class="c21 li-bullet-5"><span class="c2">You acknowledge and agree that, in the event of any third-party claim that the Application or your possession and use of the Application infringes that third party&rsquo;s intellectual property </span>
                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                        class="c2 c17">rights</span><span class="c2">, chatBCC, and not Apple, will be responsible for the investigation, defense, settlement and discharge of any such infringement claim;</span></li>
                                                                                                                                                                                                                            <li
                                                                                                                                                                                                                            class="c21 li-bullet-5"><span class="c2">&nbsp;You represent and warrant that you are not located in a country subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a &ldquo;terrorist supporting&rdquo; country, and that you are not listed on any U.S. Government list of prohibited or restricted parties;</span></li>
                                                                                                                                                                                                                                <li
                                                                                                                                                                                                                                class="c21 li-bullet-5"><span class="c2">Both you and chatBCC acknowledge and agree that, in your use of the Application, you will </span>
                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                    class="c2 c17">comply</span><span class="c2">&nbsp;with any applicable third-party terms of agreement which may affect or be affected by such use; and</span></li>
                                                                                                                                                                                                                                        <li
                                                                                                                                                                                                                                        class="c7 li-bullet-5"><span class="c2">Both you and chatBCC acknowledge and agree that Apple and Apple&rsquo;s subsidiaries are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.</span></li>
                                                                                                                                                                                                                                            </ol>
                                                                                                                                                                                                                                            <h1 class="c10"><span class="c14 c12">What else do I need to know?</span></h1>
                                                                                                                                                                                                                                            <p class="c11"><span class="c18 c0">Warranty Disclaimer</span>
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                class="c0">.</span>
                                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                                    class="c2">&nbsp;chatBCC
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        its
                                                                                                                                                                                                                                                        licensors,
                                                                                                                                                                                                                                                        suppliers,
                                                                                                                                                                                                                                                        partners,
                                                                                                                                                                                                                                                        parent,
                                                                                                                                                                                                                                                        subsidiaries
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        affiliated
                                                                                                                                                                                                                                                        entities,
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        each
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        their
                                                                                                                                                                                                                                                        respective
                                                                                                                                                                                                                                                        officers,
                                                                                                                                                                                                                                                        directors,
                                                                                                                                                                                                                                                        members,
                                                                                                                                                                                                                                                        employees,
                                                                                                                                                                                                                                                        consultants,
                                                                                                                                                                                                                                                        contract
                                                                                                                                                                                                                                                        employees,
                                                                                                                                                                                                                                                        representatives
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        agents,
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        each
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        their
                                                                                                                                                                                                                                                        respective
                                                                                                                                                                                                                                                        successors
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        assigns
                                                                                                                                                                                                                                                        (chatBCC
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        all
                                                                                                                                                                                                                                                        such
                                                                                                                                                                                                                                                        parties
                                                                                                                                                                                                                                                        together,
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        &ldquo;chatBCC
                                                                                                                                                                                                                                                        Parties&rdquo;)
                                                                                                                                                                                                                                                        make
                                                                                                                                                                                                                                                        no
                                                                                                                                                                                                                                                        representations
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        warranties
                                                                                                                                                                                                                                                        concerning
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        Services,
                                                                                                                                                                                                                                                        including
                                                                                                                                                                                                                                                        without
                                                                                                                                                                                                                                                        limitation
                                                                                                                                                                                                                                                        regarding
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        Content
                                                                                                                                                                                                                                                        contained
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        accessed
                                                                                                                                                                                                                                                        through
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        Services,
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        chatBCC
                                                                                                                                                                                                                                                        Parties
                                                                                                                                                                                                                                                        will
                                                                                                                                                                                                                                                        not
                                                                                                                                                                                                                                                        be
                                                                                                                                                                                                                                                        responsible
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        liable
                                                                                                                                                                                                                                                        for
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        accuracy,
                                                                                                                                                                                                                                                        copyright
                                                                                                                                                                                                                                                        compliance,
                                                                                                                                                                                                                                                        legality,
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        decency
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        material
                                                                                                                                                                                                                                                        contained
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        accessed
                                                                                                                                                                                                                                                        through
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        Services
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        claims,
                                                                                                                                                                                                                                                        actions,
                                                                                                                                                                                                                                                        suits
                                                                                                                                                                                                                                                        procedures,
                                                                                                                                                                                                                                                        costs,
                                                                                                                                                                                                                                                        expenses,
                                                                                                                                                                                                                                                        damages
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        liabilities
                                                                                                                                                                                                                                                        arising
                                                                                                                                                                                                                                                        out
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        use
                                                                                                                                                                                                                                                        of,
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        way
                                                                                                                                                                                                                                                        related
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        your
                                                                                                                                                                                                                                                        participation
                                                                                                                                                                                                                                                        in,
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        Services.
                                                                                                                                                                                                                                                        The
                                                                                                                                                                                                                                                        chatBCC
                                                                                                                                                                                                                                                        Parties
                                                                                                                                                                                                                                                        make
                                                                                                                                                                                                                                                        no
                                                                                                                                                                                                                                                        representations
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        warranties
                                                                                                                                                                                                                                                        regarding
                                                                                                                                                                                                                                                        suggestions
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        recommendations
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        services
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        products
                                                                                                                                                                                                                                                        offered
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        purchased
                                                                                                                                                                                                                                                        through
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        connection
                                                                                                                                                                                                                                                        with
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        Services.
                                                                                                                                                                                                                                                        THE
                                                                                                                                                                                                                                                        SERVICES
                                                                                                                                                                                                                                                        AND
                                                                                                                                                                                                                                                        CONTENT
                                                                                                                                                                                                                                                        ARE
                                                                                                                                                                                                                                                        PROVIDED
                                                                                                                                                                                                                                                        BY
                                                                                                                                                                                                                                                        CHATBCC
                                                                                                                                                                                                                                                        (AND
                                                                                                                                                                                                                                                        ITS
                                                                                                                                                                                                                                                        LICENSORS
                                                                                                                                                                                                                                                        AND
                                                                                                                                                                                                                                                        SUPPLIERS)
                                                                                                                                                                                                                                                        ON
                                                                                                                                                                                                                                                        AN
                                                                                                                                                                                                                                                        &ldquo;AS-IS&rdquo;
                                                                                                                                                                                                                                                        BASIS,
                                                                                                                                                                                                                                                        WITHOUT
                                                                                                                                                                                                                                                        WARRANTIES
                                                                                                                                                                                                                                                        OF
                                                                                                                                                                                                                                                        ANY
                                                                                                                                                                                                                                                        KIND,
                                                                                                                                                                                                                                                        EITHER
                                                                                                                                                                                                                                                        EXPRESS
                                                                                                                                                                                                                                                        OR
                                                                                                                                                                                                                                                        IMPLIED,
                                                                                                                                                                                                                                                        INCLUDING,
                                                                                                                                                                                                                                                        WITHOUT
                                                                                                                                                                                                                                                        LIMITATION,
                                                                                                                                                                                                                                                        IMPLIED
                                                                                                                                                                                                                                                        WARRANTIES
                                                                                                                                                                                                                                                        OF
                                                                                                                                                                                                                                                        MERCHANTABILITY,
                                                                                                                                                                                                                                                        FITNESS
                                                                                                                                                                                                                                                        FOR
                                                                                                                                                                                                                                                        A PARTICULAR
                                                                                                                                                                                                                                                        PURPOSE,
                                                                                                                                                                                                                                                        NON-INFRINGEMENT,
                                                                                                                                                                                                                                                        OR
                                                                                                                                                                                                                                                        THAT
                                                                                                                                                                                                                                                        USE
                                                                                                                                                                                                                                                        OF
                                                                                                                                                                                                                                                        THE
                                                                                                                                                                                                                                                        SERVICES
                                                                                                                                                                                                                                                        WILL
                                                                                                                                                                                                                                                        BE
                                                                                                                                                                                                                                                        UNINTERRUPTED
                                                                                                                                                                                                                                                        OR
                                                                                                                                                                                                                                                        ERROR-FREE.
                                                                                                                                                                                                                                                        SOME
                                                                                                                                                                                                                                                        STATES
                                                                                                                                                                                                                                                        DO
                                                                                                                                                                                                                                                        NOT
                                                                                                                                                                                                                                                        ALLOW
                                                                                                                                                                                                                                                        LIMITATIONS
                                                                                                                                                                                                                                                        ON
                                                                                                                                                                                                                                                        HOW
                                                                                                                                                                                                                                                        LONG
                                                                                                                                                                                                                                                        AN
                                                                                                                                                                                                                                                        IMPLIED
                                                                                                                                                                                                                                                        WARRANTY
                                                                                                                                                                                                                                                        LASTS,
                                                                                                                                                                                                                                                        SO
                                                                                                                                                                                                                                                        THE
                                                                                                                                                                                                                                                        ABOVE
                                                                                                                                                                                                                                                        LIMITATIONS
                                                                                                                                                                                                                                                        MAY
                                                                                                                                                                                                                                                        NOT
                                                                                                                                                                                                                                                        APPLY
                                                                                                                                                                                                                                                        TO
                                                                                                                                                                                                                                                        YOU.</span>
                                                                                                                                                                                                                                            </p>
                                                                                                                                                                                                                                            <a id="id.2et92p0"></a>
                                                                                                                                                                                                                                            <p class="c11"><span class="c18 c0">Limitation of Liability</span>
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                class="c2">. TO THE FULLEST
                                                                                                                                                                                                                                                    EXTENT
                                                                                                                                                                                                                                                    ALLOWED
                                                                                                                                                                                                                                                    BY APPLICABLE
                                                                                                                                                                                                                                                    LAW, UNDER
                                                                                                                                                                                                                                                    NO CIRCUMSTANCES
                                                                                                                                                                                                                                                    AND UNDER
                                                                                                                                                                                                                                                    NO LEGAL
                                                                                                                                                                                                                                                    THEORY
                                                                                                                                                                                                                                                    (INCLUDING,
                                                                                                                                                                                                                                                    WITHOUT
                                                                                                                                                                                                                                                    LIMITATION,
                                                                                                                                                                                                                                                    TORT, CONTRACT,
                                                                                                                                                                                                                                                    STRICT
                                                                                                                                                                                                                                                    LIABILITY,
                                                                                                                                                                                                                                                    OR OTHERWISE)
                                                                                                                                                                                                                                                    SHALL ANY
                                                                                                                                                                                                                                                    OF THE
                                                                                                                                                                                                                                                    CHATBCC
                                                                                                                                                                                                                                                    PARTIES
                                                                                                                                                                                                                                                    BE LIABLE
                                                                                                                                                                                                                                                    TO YOU
                                                                                                                                                                                                                                                    OR TO ANY
                                                                                                                                                                                                                                                    OTHER PERSON
                                                                                                                                                                                                                                                    FOR (A)
                                                                                                                                                                                                                                                    ANY INDIRECT,
                                                                                                                                                                                                                                                    SPECIAL,
                                                                                                                                                                                                                                                    INCIDENTAL,
                                                                                                                                                                                                                                                    PUNITIVE
                                                                                                                                                                                                                                                    OR CONSEQUENTIAL
                                                                                                                                                                                                                                                    DAMAGES
                                                                                                                                                                                                                                                    OF ANY
                                                                                                                                                                                                                                                    KIND, INCLUDING
                                                                                                                                                                                                                                                    DAMAGES
                                                                                                                                                                                                                                                    FOR LOST
                                                                                                                                                                                                                                                    PROFITS,
                                                                                                                                                                                                                                                    BUSINESS
                                                                                                                                                                                                                                                    INTERRUPTION,
                                                                                                                                                                                                                                                    LOSS OF
                                                                                                                                                                                                                                                    DATA, LOSS
                                                                                                                                                                                                                                                    OF GOODWILL,
                                                                                                                                                                                                                                                    WORK STOPPAGE,
                                                                                                                                                                                                                                                    ACCURACY
                                                                                                                                                                                                                                                    OF RESULTS,
                                                                                                                                                                                                                                                    OR COMPUTER
                                                                                                                                                                                                                                                    FAILURE
                                                                                                                                                                                                                                                    OR MALFUNCTION,
                                                                                                                                                                                                                                                    (B) ANY
                                                                                                                                                                                                                                                    SUBSTITUTE
                                                                                                                                                                                                                                                    GOODS,
                                                                                                                                                                                                                                                    SERVICES
                                                                                                                                                                                                                                                    OR TECHNOLOGY,
                                                                                                                                                                                                                                                    (C) ANY
                                                                                                                                                                                                                                                    AMOUNT,
                                                                                                                                                                                                                                                    IN THE
                                                                                                                                                                                                                                                    AGGREGATE,
                                                                                                                                                                                                                                                    IN EXCESS
                                                                                                                                                                                                                                                    OF ONE-HUNDRED
                                                                                                                                                                                                                                                    ($100)
                                                                                                                                                                                                                                                    DOLLARS
                                                                                                                                                                                                                                                    OR (D)
                                                                                                                                                                                                                                                    ANY MATTER
                                                                                                                                                                                                                                                    BEYOND
                                                                                                                                                                                                                                                    OUR REASONABLE
                                                                                                                                                                                                                                                    CONTROL.
                                                                                                                                                                                                                                                    SOME STATES
                                                                                                                                                                                                                                                    DO NOT
                                                                                                                                                                                                                                                    ALLOW THE
                                                                                                                                                                                                                                                    EXCLUSION
                                                                                                                                                                                                                                                    OR LIMITATION
                                                                                                                                                                                                                                                    OF INCIDENTAL
                                                                                                                                                                                                                                                    OR CONSEQUENTIAL
                                                                                                                                                                                                                                                    OR CERTAIN
                                                                                                                                                                                                                                                    OTHER DAMAGES,
                                                                                                                                                                                                                                                    SO THE
                                                                                                                                                                                                                                                    ABOVE LIMITATION
                                                                                                                                                                                                                                                    AND EXCLUSIONS
                                                                                                                                                                                                                                                    MAY NOT
                                                                                                                                                                                                                                                    APPLY TO
                                                                                                                                                                                                                                                    YOU.</span>
                                                                                                                                                                                                                                            </p>
                                                                                                                                                                                                                                            <p class="c11"
                                                                                                                                                                                                                                            id="h.tyjcwt"><span class="c18 c0">Indemnity</span>
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                class="c2">. You agree
                                                                                                                                                                                                                                                    to indemnify
                                                                                                                                                                                                                                                    and hold
                                                                                                                                                                                                                                                    the chatBCC
                                                                                                                                                                                                                                                    Parties
                                                                                                                                                                                                                                                    harmless
                                                                                                                                                                                                                                                    from and
                                                                                                                                                                                                                                                    against
                                                                                                                                                                                                                                                    any and
                                                                                                                                                                                                                                                    all claims,
                                                                                                                                                                                                                                                    liabilities,
                                                                                                                                                                                                                                                    damages
                                                                                                                                                                                                                                                    (actual
                                                                                                                                                                                                                                                    and consequential),
                                                                                                                                                                                                                                                    losses
                                                                                                                                                                                                                                                    and expenses
                                                                                                                                                                                                                                                    (including
                                                                                                                                                                                                                                                    attorneys&rsquo;
                                                                                                                                                                                                                                                    fees) arising
                                                                                                                                                                                                                                                    from or
                                                                                                                                                                                                                                                    in any
                                                                                                                                                                                                                                                    way related
                                                                                                                                                                                                                                                    to any
                                                                                                                                                                                                                                                    claims
                                                                                                                                                                                                                                                    relating
                                                                                                                                                                                                                                                    to (a)
                                                                                                                                                                                                                                                    your use
                                                                                                                                                                                                                                                    of the
                                                                                                                                                                                                                                                    Services
                                                                                                                                                                                                                                                    (including
                                                                                                                                                                                                                                                    any actions
                                                                                                                                                                                                                                                    taken by
                                                                                                                                                                                                                                                    a third
                                                                                                                                                                                                                                                    party using
                                                                                                                                                                                                                                                    your account),
                                                                                                                                                                                                                                                    and (b)
                                                                                                                                                                                                                                                    your violation
                                                                                                                                                                                                                                                    of these
                                                                                                                                                                                                                                                    Terms.
                                                                                                                                                                                                                                                    In the
                                                                                                                                                                                                                                                    event of
                                                                                                                                                                                                                                                    such a
                                                                                                                                                                                                                                                    claim,
                                                                                                                                                                                                                                                    suit, or
                                                                                                                                                                                                                                                    action
                                                                                                                                                                                                                                                    (&ldquo;Claim&rdquo;),
                                                                                                                                                                                                                                                    we will
                                                                                                                                                                                                                                                    attempt
                                                                                                                                                                                                                                                    to provide
                                                                                                                                                                                                                                                    notice
                                                                                                                                                                                                                                                    of the
                                                                                                                                                                                                                                                    Claim to
                                                                                                                                                                                                                                                    the contact
                                                                                                                                                                                                                                                    information
                                                                                                                                                                                                                                                    we have
                                                                                                                                                                                                                                                    for your
                                                                                                                                                                                                                                                    account
                                                                                                                                                                                                                                                    (provided
                                                                                                                                                                                                                                                    that failure
                                                                                                                                                                                                                                                    to deliver
                                                                                                                                                                                                                                                    such notice
                                                                                                                                                                                                                                                    shall not
                                                                                                                                                                                                                                                    eliminate
                                                                                                                                                                                                                                                    or reduce
                                                                                                                                                                                                                                                    your indemnification
                                                                                                                                                                                                                                                    obligations
                                                                                                                                                                                                                                                    hereunder).</span>
                                                                                                                                                                                                                                            </p>
                                                                                                                                                                                                                                            <p class="c11"><span class="c18 c0">Assignment</span>
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                class="c2">. You may not
                                                                                                                                                                                                                                                    assign,
                                                                                                                                                                                                                                                    delegate
                                                                                                                                                                                                                                                    or transfer
                                                                                                                                                                                                                                                    these Terms
                                                                                                                                                                                                                                                    or your
                                                                                                                                                                                                                                                    rights
                                                                                                                                                                                                                                                    or obligations
                                                                                                                                                                                                                                                    hereunder,
                                                                                                                                                                                                                                                    or your
                                                                                                                                                                                                                                                    Services
                                                                                                                                                                                                                                                    account,
                                                                                                                                                                                                                                                    in any
                                                                                                                                                                                                                                                    way (by
                                                                                                                                                                                                                                                    operation
                                                                                                                                                                                                                                                    of law
                                                                                                                                                                                                                                                    or otherwise)
                                                                                                                                                                                                                                                    without
                                                                                                                                                                                                                                                    chatBCC&#39;s
                                                                                                                                                                                                                                                    prior written
                                                                                                                                                                                                                                                    consent.
                                                                                                                                                                                                                                                    We may
                                                                                                                                                                                                                                                    transfer,
                                                                                                                                                                                                                                                    assign,
                                                                                                                                                                                                                                                    or delegate
                                                                                                                                                                                                                                                    these Terms
                                                                                                                                                                                                                                                    and our
                                                                                                                                                                                                                                                    rights
                                                                                                                                                                                                                                                    and obligations
                                                                                                                                                                                                                                                    without
                                                                                                                                                                                                                                                    consent.</span>
                                                                                                                                                                                                                                            </p>
                                                                                                                                                                                                                                            <p class="c11"><span class="c18 c0">Choice of Law</span>
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                class="c2">. These Terms
                                                                                                                                                                                                                                                    are governed
                                                                                                                                                                                                                                                    by and
                                                                                                                                                                                                                                                    will be
                                                                                                                                                                                                                                                    construed
                                                                                                                                                                                                                                                    under the
                                                                                                                                                                                                                                                    Federal
                                                                                                                                                                                                                                                    Arbitration
                                                                                                                                                                                                                                                    Act, applicable
                                                                                                                                                                                                                                                    federal
                                                                                                                                                                                                                                                    law, and
                                                                                                                                                                                                                                                    the laws
                                                                                                                                                                                                                                                    of the
                                                                                                                                                                                                                                                    State of
                                                                                                                                                                                                                                                    California,
                                                                                                                                                                                                                                                    without
                                                                                                                                                                                                                                                    regard
                                                                                                                                                                                                                                                    to the
                                                                                                                                                                                                                                                    conflicts
                                                                                                                                                                                                                                                    of laws
                                                                                                                                                                                                                                                    provisions
                                                                                                                                                                                                                                                    thereof.</span>
                                                                                                                                                                                                                                            </p>
                                                                                                                                                                                                                                            <a id="id.3dy6vkm"></a>
                                                                                                                                                                                                                                            <p class="c11"><span class="c18 c0">Arbitration Agreement</span>
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                class="c0">. </span>
                                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                                    class="c2">Please
                                                                                                                                                                                                                                                        read
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        following
                                                                                                                                                                                                                                                        ARBITRATION
                                                                                                                                                                                                                                                        AGREEMENT
                                                                                                                                                                                                                                                        carefully
                                                                                                                                                                                                                                                        because
                                                                                                                                                                                                                                                        it
                                                                                                                                                                                                                                                        requires
                                                                                                                                                                                                                                                        you
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        arbitrate
                                                                                                                                                                                                                                                        certain
                                                                                                                                                                                                                                                        disputes
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        claims
                                                                                                                                                                                                                                                        with
                                                                                                                                                                                                                                                        chatBCC
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        limits
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        manner
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        which
                                                                                                                                                                                                                                                        you
                                                                                                                                                                                                                                                        can
                                                                                                                                                                                                                                                        seek
                                                                                                                                                                                                                                                        relief
                                                                                                                                                                                                                                                        from
                                                                                                                                                                                                                                                        chatBCC.
                                                                                                                                                                                                                                                        Both
                                                                                                                                                                                                                                                        you
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        chatBCC
                                                                                                                                                                                                                                                        acknowledge
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        agree
                                                                                                                                                                                                                                                        that
                                                                                                                                                                                                                                                        for
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        purposes
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        dispute
                                                                                                                                                                                                                                                        arising
                                                                                                                                                                                                                                                        out
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        relating
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        subject
                                                                                                                                                                                                                                                        matter
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        these
                                                                                                                                                                                                                                                        Terms,
                                                                                                                                                                                                                                                        chatBCC&#39;s
                                                                                                                                                                                                                                                        officers,
                                                                                                                                                                                                                                                        directors,
                                                                                                                                                                                                                                                        employees
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        independent
                                                                                                                                                                                                                                                        contractors
                                                                                                                                                                                                                                                        (&ldquo;Personnel&rdquo;)
                                                                                                                                                                                                                                                        are
                                                                                                                                                                                                                                                        third-party
                                                                                                                                                                                                                                                        beneficiaries
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        these
                                                                                                                                                                                                                                                        Terms,
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        that
                                                                                                                                                                                                                                                        upon
                                                                                                                                                                                                                                                        your
                                                                                                                                                                                                                                                        acceptance
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        these
                                                                                                                                                                                                                                                        Terms,
                                                                                                                                                                                                                                                        Personnel
                                                                                                                                                                                                                                                        will
                                                                                                                                                                                                                                                        have
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        right
                                                                                                                                                                                                                                                        (and
                                                                                                                                                                                                                                                        will
                                                                                                                                                                                                                                                        be
                                                                                                                                                                                                                                                        deemed
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        have
                                                                                                                                                                                                                                                        accepted
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        right)
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        enforce
                                                                                                                                                                                                                                                        these
                                                                                                                                                                                                                                                        Terms
                                                                                                                                                                                                                                                        against
                                                                                                                                                                                                                                                        you
                                                                                                                                                                                                                                                        as
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        third-party
                                                                                                                                                                                                                                                        beneficiary
                                                                                                                                                                                                                                                        hereof.</span>
                                                                                                                                                                                                                                            </p>
                                                                                                                                                                                                                                            <p class="c11"><span class="c0">(a) Arbitration Rules; Applicability of Arbitration Agreement</span>
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                class="c13">. The parties
                                                                                                                                                                                                                                                    shall use
                                                                                                                                                                                                                                                    their best
                                                                                                                                                                                                                                                    efforts
                                                                                                                                                                                                                                                    to settle
                                                                                                                                                                                                                                                    any dispute,
                                                                                                                                                                                                                                                    claim,
                                                                                                                                                                                                                                                    question,
                                                                                                                                                                                                                                                    or disagreement
                                                                                                                                                                                                                                                    arising
                                                                                                                                                                                                                                                    out of
                                                                                                                                                                                                                                                    or relating
                                                                                                                                                                                                                                                    to the
                                                                                                                                                                                                                                                    subject
                                                                                                                                                                                                                                                    matter
                                                                                                                                                                                                                                                    of these
                                                                                                                                                                                                                                                    Terms directly
                                                                                                                                                                                                                                                    through
                                                                                                                                                                                                                                                    good-faith
                                                                                                                                                                                                                                                    negotiations,
                                                                                                                                                                                                                                                    which shall
                                                                                                                                                                                                                                                    be a precondition
                                                                                                                                                                                                                                                    to either
                                                                                                                                                                                                                                                    party initiating
                                                                                                                                                                                                                                                    arbitration.
                                                                                                                                                                                                                                                    If such
                                                                                                                                                                                                                                                    negotiations
                                                                                                                                                                                                                                                    do not
                                                                                                                                                                                                                                                    resolve
                                                                                                                                                                                                                                                    the dispute,
                                                                                                                                                                                                                                                    it shall
                                                                                                                                                                                                                                                    be finally
                                                                                                                                                                                                                                                    settled
                                                                                                                                                                                                                                                    by binding
                                                                                                                                                                                                                                                    arbitration
                                                                                                                                                                                                                                                    in San
                                                                                                                                                                                                                                                    Francisco
                                                                                                                                                                                                                                                    County,
                                                                                                                                                                                                                                                    California.
                                                                                                                                                                                                                                                    The arbitration
                                                                                                                                                                                                                                                    will proceed
                                                                                                                                                                                                                                                    in the
                                                                                                                                                                                                                                                    English
                                                                                                                                                                                                                                                    language,
                                                                                                                                                                                                                                                    in accordance
                                                                                                                                                                                                                                                    with the
                                                                                                                                                                                                                                                    JAMS Streamlined
                                                                                                                                                                                                                                                    </span>
                                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                                    class="c13 c17">Arbitration
                                                                                                                                                                                                                                                        Rules
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        Procedures
                                                                                                                                                                                                                                                        (the
                                                                                                                                                                                                                                                        &ldquo;Rules&rdquo;)
                                                                                                                                                                                                                                                        then
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        effect,
                                                                                                                                                                                                                                                        by
                                                                                                                                                                                                                                                        one
                                                                                                                                                                                                                                                        commercial
                                                                                                                                                                                                                                                        arbitrator
                                                                                                                                                                                                                                                        with
                                                                                                                                                                                                                                                        substantial
                                                                                                                                                                                                                                                        experience
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        resolving
                                                                                                                                                                                                                                                        intellectual
                                                                                                                                                                                                                                                        property
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        commercial
                                                                                                                                                                                                                                                        contract
                                                                                                                                                                                                                                                        disputes.
                                                                                                                                                                                                                                                        The
                                                                                                                                                                                                                                                        arbitrator
                                                                                                                                                                                                                                                        shall
                                                                                                                                                                                                                                                        be
                                                                                                                                                                                                                                                        selected
                                                                                                                                                                                                                                                        from
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        appropriate
                                                                                                                                                                                                                                                        list
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        JAMS
                                                                                                                                                                                                                                                        arbitrators
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        accordance
                                                                                                                                                                                                                                                        with
                                                                                                                                                                                                                                                        such
                                                                                                                                                                                                                                                        Rules.
                                                                                                                                                                                                                                                        Judgment
                                                                                                                                                                                                                                                        upon
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        award
                                                                                                                                                                                                                                                        rendered
                                                                                                                                                                                                                                                        by
                                                                                                                                                                                                                                                        such
                                                                                                                                                                                                                                                        arbitrator
                                                                                                                                                                                                                                                        may
                                                                                                                                                                                                                                                        be
                                                                                                                                                                                                                                                        entered
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        court
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        competent
                                                                                                                                                                                                                                                        jurisdiction.
                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                            </p>
                                                                                                                                                                                                                                            <p class="c11"><span class="c13 c17">(b) </span>
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                class="c0 c17">Costs of Arbitration</span>
                                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                                    class="c2 c17">. The Rules
                                                                                                                                                                                                                                                        will
                                                                                                                                                                                                                                                        govern
                                                                                                                                                                                                                                                        payment
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        all
                                                                                                                                                                                                                                                        arbitration
                                                                                                                                                                                                                                                        fees.
                                                                                                                                                                                                                                                        chatBCC
                                                                                                                                                                                                                                                        will
                                                                                                                                                                                                                                                        pay
                                                                                                                                                                                                                                                        all
                                                                                                                                                                                                                                                        arbitration
                                                                                                                                                                                                                                                        fees
                                                                                                                                                                                                                                                        for
                                                                                                                                                                                                                                                        claims
                                                                                                                                                                                                                                                        less
                                                                                                                                                                                                                                                        than
                                                                                                                                                                                                                                                        seventy-five
                                                                                                                                                                                                                                                        thousand
                                                                                                                                                                                                                                                        ($75,000)
                                                                                                                                                                                                                                                        dollars.
                                                                                                                                                                                                                                                        chatBCC
                                                                                                                                                                                                                                                        will
                                                                                                                                                                                                                                                        not
                                                                                                                                                                                                                                                        seek
                                                                                                                                                                                                                                                        its
                                                                                                                                                                                                                                                        attorneys&rsquo;
                                                                                                                                                                                                                                                        fees
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        costs
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        arbitration
                                                                                                                                                                                                                                                        unless
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        arbitrator
                                                                                                                                                                                                                                                        determines
                                                                                                                                                                                                                                                        that
                                                                                                                                                                                                                                                        your
                                                                                                                                                                                                                                                        claim
                                                                                                                                                                                                                                                        is
                                                                                                                                                                                                                                                        frivolous.
                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                            </p>
                                                                                                                                                                                                                                            <p class="c11"><span class="c13 c17">(c) </span>
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                class="c0 c17">Small Claims
                                                                                                                                                                                                                                                    Court;
                                                                                                                                                                                                                                                    Infringement</span>
                                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                                    class="c2 c17">. Either
                                                                                                                                                                                                                                                        you
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        chatBCC
                                                                                                                                                                                                                                                        may
                                                                                                                                                                                                                                                        assert
                                                                                                                                                                                                                                                        claims,
                                                                                                                                                                                                                                                        if
                                                                                                                                                                                                                                                        they
                                                                                                                                                                                                                                                        qualify,
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        small
                                                                                                                                                                                                                                                        claims
                                                                                                                                                                                                                                                        court
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        San
                                                                                                                                                                                                                                                        Francisco
                                                                                                                                                                                                                                                        County,
                                                                                                                                                                                                                                                        California
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        United
                                                                                                                                                                                                                                                        States
                                                                                                                                                                                                                                                        county
                                                                                                                                                                                                                                                        where
                                                                                                                                                                                                                                                        you
                                                                                                                                                                                                                                                        live
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        work.
                                                                                                                                                                                                                                                        Furthermore,
                                                                                                                                                                                                                                                        notwithstanding
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        foregoing
                                                                                                                                                                                                                                                        obligation
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        arbitrate
                                                                                                                                                                                                                                                        disputes,
                                                                                                                                                                                                                                                        each
                                                                                                                                                                                                                                                        party
                                                                                                                                                                                                                                                        shall
                                                                                                                                                                                                                                                        have
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        right
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        pursue
                                                                                                                                                                                                                                                        injunctive
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        other
                                                                                                                                                                                                                                                        equitable
                                                                                                                                                                                                                                                        relief
                                                                                                                                                                                                                                                        at
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        time,
                                                                                                                                                                                                                                                        from
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        court
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        competent
                                                                                                                                                                                                                                                        jurisdiction,
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        prevent
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        actual
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        threatened
                                                                                                                                                                                                                                                        infringement,
                                                                                                                                                                                                                                                        misappropriation
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        violation
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        a party&#39;s
                                                                                                                                                                                                                                                        copyrights,
                                                                                                                                                                                                                                                        trademarks,
                                                                                                                                                                                                                                                        trade
                                                                                                                                                                                                                                                        secrets,
                                                                                                                                                                                                                                                        patents
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        other
                                                                                                                                                                                                                                                        intellectual
                                                                                                                                                                                                                                                        property
                                                                                                                                                                                                                                                        rights.
                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                            </p>
                                                                                                                                                                                                                                            <p class="c11"><span class="c13">(d) </span>
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                class="c0">Waiver of Jury
                                                                                                                                                                                                                                                    Trial</span>
                                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                                    class="c13">. YOU AND
                                                                                                                                                                                                                                                        CHATBCC
                                                                                                                                                                                                                                                        WAIVE
                                                                                                                                                                                                                                                        ANY
                                                                                                                                                                                                                                                        CONSTITUTIONAL
                                                                                                                                                                                                                                                        AND
                                                                                                                                                                                                                                                        STATUTORY
                                                                                                                                                                                                                                                        RIGHTS
                                                                                                                                                                                                                                                        TO
                                                                                                                                                                                                                                                        GO
                                                                                                                                                                                                                                                        TO
                                                                                                                                                                                                                                                        COURT
                                                                                                                                                                                                                                                        AND
                                                                                                                                                                                                                                                        HAVE
                                                                                                                                                                                                                                                        A TRIAL
                                                                                                                                                                                                                                                        IN
                                                                                                                                                                                                                                                        FRONT
                                                                                                                                                                                                                                                        OF
                                                                                                                                                                                                                                                        A JUDGE
                                                                                                                                                                                                                                                        OR
                                                                                                                                                                                                                                                        JURY.
                                                                                                                                                                                                                                                        You
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        chatBCC
                                                                                                                                                                                                                                                        are
                                                                                                                                                                                                                                                        instead
                                                                                                                                                                                                                                                        choosing
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        have
                                                                                                                                                                                                                                                        claims
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        disputes
                                                                                                                                                                                                                                                        resolved
                                                                                                                                                                                                                                                        by
                                                                                                                                                                                                                                                        arbitration.
                                                                                                                                                                                                                                                        Arbitration
                                                                                                                                                                                                                                                        procedures
                                                                                                                                                                                                                                                        are
                                                                                                                                                                                                                                                        typically
                                                                                                                                                                                                                                                        more
                                                                                                                                                                                                                                                        limited,
                                                                                                                                                                                                                                                        more
                                                                                                                                                                                                                                                        efficient,
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        less
                                                                                                                                                                                                                                                        costly
                                                                                                                                                                                                                                                        than
                                                                                                                                                                                                                                                        rules
                                                                                                                                                                                                                                                        applicable
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        court
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        are
                                                                                                                                                                                                                                                        subject
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        very
                                                                                                                                                                                                                                                        limited
                                                                                                                                                                                                                                                        review
                                                                                                                                                                                                                                                        by
                                                                                                                                                                                                                                                        a court.
                                                                                                                                                                                                                                                        In
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        litigation
                                                                                                                                                                                                                                                        between
                                                                                                                                                                                                                                                        you
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        chatBCC
                                                                                                                                                                                                                                                        over
                                                                                                                                                                                                                                                        whether
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        vacate
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        enforce
                                                                                                                                                                                                                                                        an
                                                                                                                                                                                                                                                        arbitration
                                                                                                                                                                                                                                                        award,
                                                                                                                                                                                                                                                        YOU
                                                                                                                                                                                                                                                        AND
                                                                                                                                                                                                                                                        CHATBCC
                                                                                                                                                                                                                                                        WAIVE
                                                                                                                                                                                                                                                        ALL
                                                                                                                                                                                                                                                        RIGHTS
                                                                                                                                                                                                                                                        TO
                                                                                                                                                                                                                                                        A JURY
                                                                                                                                                                                                                                                        TRIAL,
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        elect
                                                                                                                                                                                                                                                        instead
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        have
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        dispute
                                                                                                                                                                                                                                                        be
                                                                                                                                                                                                                                                        resolved
                                                                                                                                                                                                                                                        by
                                                                                                                                                                                                                                                        a judge.</span>
                                                                                                                                                                                                                                            </p>
                                                                                                                                                                                                                                            <p class="c11"><span class="c13">(e) </span>
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                class="c0">Waiver of Class
                                                                                                                                                                                                                                                    or Consolidated
                                                                                                                                                                                                                                                    Actions</span>
                                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                                    class="c13">. ALL CLAIMS
                                                                                                                                                                                                                                                        AND
                                                                                                                                                                                                                                                        DISPUTES
                                                                                                                                                                                                                                                        WITHIN
                                                                                                                                                                                                                                                        THE
                                                                                                                                                                                                                                                        SCOPE
                                                                                                                                                                                                                                                        OF
                                                                                                                                                                                                                                                        THIS
                                                                                                                                                                                                                                                        ARBITRATION
                                                                                                                                                                                                                                                        AGREEMENT
                                                                                                                                                                                                                                                        MUST
                                                                                                                                                                                                                                                        BE
                                                                                                                                                                                                                                                        ARBITRATED
                                                                                                                                                                                                                                                        OR
                                                                                                                                                                                                                                                        LITIGATED
                                                                                                                                                                                                                                                        ON
                                                                                                                                                                                                                                                        AN
                                                                                                                                                                                                                                                        INDIVIDUAL
                                                                                                                                                                                                                                                        BASIS
                                                                                                                                                                                                                                                        AND
                                                                                                                                                                                                                                                        NOT
                                                                                                                                                                                                                                                        ON
                                                                                                                                                                                                                                                        A CLASS
                                                                                                                                                                                                                                                        BASIS.
                                                                                                                                                                                                                                                        CLAIMS
                                                                                                                                                                                                                                                        OF
                                                                                                                                                                                                                                                        MORE
                                                                                                                                                                                                                                                        THAN
                                                                                                                                                                                                                                                        ONE
                                                                                                                                                                                                                                                        CUSTOMER
                                                                                                                                                                                                                                                        OR
                                                                                                                                                                                                                                                        USER
                                                                                                                                                                                                                                                        CANNOT
                                                                                                                                                                                                                                                        BE
                                                                                                                                                                                                                                                        ARBITRATED
                                                                                                                                                                                                                                                        OR
                                                                                                                                                                                                                                                        LITIGATED
                                                                                                                                                                                                                                                        JOINTLY
                                                                                                                                                                                                                                                        OR
                                                                                                                                                                                                                                                        CONSOLIDATED
                                                                                                                                                                                                                                                        WITH
                                                                                                                                                                                                                                                        THOSE
                                                                                                                                                                                                                                                        OF
                                                                                                                                                                                                                                                        ANY
                                                                                                                                                                                                                                                        OTHER
                                                                                                                                                                                                                                                        CUSTOMER
                                                                                                                                                                                                                                                        OR
                                                                                                                                                                                                                                                        USER.
                                                                                                                                                                                                                                                        If
                                                                                                                                                                                                                                                        however,
                                                                                                                                                                                                                                                        this
                                                                                                                                                                                                                                                        waiver
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        class
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        consolidated
                                                                                                                                                                                                                                                        actions
                                                                                                                                                                                                                                                        is
                                                                                                                                                                                                                                                        deemed
                                                                                                                                                                                                                                                        invalid
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        unenforceable,
                                                                                                                                                                                                                                                        neither
                                                                                                                                                                                                                                                        you
                                                                                                                                                                                                                                                        nor
                                                                                                                                                                                                                                                        chatBCC
                                                                                                                                                                                                                                                        is
                                                                                                                                                                                                                                                        entitled
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        arbitration;
                                                                                                                                                                                                                                                        instead
                                                                                                                                                                                                                                                        all
                                                                                                                                                                                                                                                        claims
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        disputes
                                                                                                                                                                                                                                                        will
                                                                                                                                                                                                                                                        be
                                                                                                                                                                                                                                                        resolved
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        a court
                                                                                                                                                                                                                                                        as
                                                                                                                                                                                                                                                        set
                                                                                                                                                                                                                                                        forth
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        (g)
                                                                                                                                                                                                                                                        below.</span>
                                                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                                                        class="c3">&nbsp;</span>
                                                                                                                                                                                                                                            </p>
                                                                                                                                                                                                                                            <p class="c11"><span class="c13">(f) </span>
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                class="c0">Opt-out</span>
                                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                                    class="c2">. You have
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        right
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        opt
                                                                                                                                                                                                                                                        out
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        provisions
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        this
                                                                                                                                                                                                                                                        Section
                                                                                                                                                                                                                                                        by
                                                                                                                                                                                                                                                        sending
                                                                                                                                                                                                                                                        written
                                                                                                                                                                                                                                                        notice
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        your
                                                                                                                                                                                                                                                        decision
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        opt
                                                                                                                                                                                                                                                        out
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        following
                                                                                                                                                                                                                                                        address:
                                                                                                                                                                                                                                                        548
                                                                                                                                                                                                                                                        Market
                                                                                                                                                                                                                                                        St
                                                                                                                                                                                                                                                        PMB
                                                                                                                                                                                                                                                        478800,
                                                                                                                                                                                                                                                        San
                                                                                                                                                                                                                                                        Francisco,
                                                                                                                                                                                                                                                        California
                                                                                                                                                                                                                                                        94104-5401
                                                                                                                                                                                                                                                        postmarked
                                                                                                                                                                                                                                                        within
                                                                                                                                                                                                                                                        thirty
                                                                                                                                                                                                                                                        (30)
                                                                                                                                                                                                                                                        days
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        first
                                                                                                                                                                                                                                                        accepting
                                                                                                                                                                                                                                                        these
                                                                                                                                                                                                                                                        Terms.
                                                                                                                                                                                                                                                        You
                                                                                                                                                                                                                                                        must
                                                                                                                                                                                                                                                        include
                                                                                                                                                                                                                                                        (i)
                                                                                                                                                                                                                                                        your
                                                                                                                                                                                                                                                        name
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        residence
                                                                                                                                                                                                                                                        address,
                                                                                                                                                                                                                                                        (ii)
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        email
                                                                                                                                                                                                                                                        address
                                                                                                                                                                                                                                                        and/or
                                                                                                                                                                                                                                                        telephone
                                                                                                                                                                                                                                                        number
                                                                                                                                                                                                                                                        associated
                                                                                                                                                                                                                                                        with
                                                                                                                                                                                                                                                        your
                                                                                                                                                                                                                                                        account,
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        (iii)
                                                                                                                                                                                                                                                        a clear
                                                                                                                                                                                                                                                        statement
                                                                                                                                                                                                                                                        that
                                                                                                                                                                                                                                                        you
                                                                                                                                                                                                                                                        want
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        opt
                                                                                                                                                                                                                                                        out
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        these
                                                                                                                                                                                                                                                        Terms&rsquo;
                                                                                                                                                                                                                                                        arbitration
                                                                                                                                                                                                                                                        agreement.</span>
                                                                                                                                                                                                                                            </p>
                                                                                                                                                                                                                                            <p class="c11"><span class="c13">(g) </span>
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                class="c0">Exclusive Venue</span>
                                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                                    class="c13">. If you
                                                                                                                                                                                                                                                        send
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        opt-out
                                                                                                                                                                                                                                                        notice
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        (f),
                                                                                                                                                                                                                                                        and/or
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        circumstances
                                                                                                                                                                                                                                                        where
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        foregoing
                                                                                                                                                                                                                                                        arbitration
                                                                                                                                                                                                                                                        agreement
                                                                                                                                                                                                                                                        permits
                                                                                                                                                                                                                                                        either
                                                                                                                                                                                                                                                        you
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        chatBCC
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        litigate
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        dispute
                                                                                                                                                                                                                                                        arising
                                                                                                                                                                                                                                                        out
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        relating
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        subject
                                                                                                                                                                                                                                                        matter
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        these
                                                                                                                                                                                                                                                        Terms
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        court,
                                                                                                                                                                                                                                                        then
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        foregoing
                                                                                                                                                                                                                                                        arbitration
                                                                                                                                                                                                                                                        agreement
                                                                                                                                                                                                                                                        will
                                                                                                                                                                                                                                                        not
                                                                                                                                                                                                                                                        apply
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        either
                                                                                                                                                                                                                                                        party,
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        both
                                                                                                                                                                                                                                                        you
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        chatBCC
                                                                                                                                                                                                                                                        agree
                                                                                                                                                                                                                                                        that
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        judicial
                                                                                                                                                                                                                                                        proceeding
                                                                                                                                                                                                                                                        (other
                                                                                                                                                                                                                                                        than
                                                                                                                                                                                                                                                        small
                                                                                                                                                                                                                                                        claims
                                                                                                                                                                                                                                                        actions)
                                                                                                                                                                                                                                                        will
                                                                                                                                                                                                                                                        be
                                                                                                                                                                                                                                                        brought
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        state
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        federal
                                                                                                                                                                                                                                                        courts
                                                                                                                                                                                                                                                        </span>
                                                                                                                                                                                                                                                        <span
                                                                                                                                                                                                                                                        class="c2 c17">located
                                                                                                                                                                                                                                                            in,
                                                                                                                                                                                                                                                            respectively,
                                                                                                                                                                                                                                                            San
                                                                                                                                                                                                                                                            Francisco
                                                                                                                                                                                                                                                            County,
                                                                                                                                                                                                                                                            California,
                                                                                                                                                                                                                                                            or
                                                                                                                                                                                                                                                            the
                                                                                                                                                                                                                                                            federal
                                                                                                                                                                                                                                                            district
                                                                                                                                                                                                                                                            in
                                                                                                                                                                                                                                                            which
                                                                                                                                                                                                                                                            that
                                                                                                                                                                                                                                                            county
                                                                                                                                                                                                                                                            falls.
                                                                                                                                                                                                                                                            </span>
                                                                                                                                                                                                                                            </p>
                                                                                                                                                                                                                                            <p class="c11"><span class="c13 c17">(h) </span>
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                class="c0 c17">Severability</span>
                                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                                    class="c13 c17">. If the
                                                                                                                                                                                                                                                        prohibition
                                                                                                                                                                                                                                                        against
                                                                                                                                                                                                                                                        class
                                                                                                                                                                                                                                                        actions
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        other
                                                                                                                                                                                                                                                        claims
                                                                                                                                                                                                                                                        brought
                                                                                                                                                                                                                                                        on
                                                                                                                                                                                                                                                        behalf
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        third
                                                                                                                                                                                                                                                        parties
                                                                                                                                                                                                                                                        contained
                                                                                                                                                                                                                                                        above
                                                                                                                                                                                                                                                        is
                                                                                                                                                                                                                                                        found
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        be
                                                                                                                                                                                                                                                        unenforceable,
                                                                                                                                                                                                                                                        then
                                                                                                                                                                                                                                                        all
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        preceding
                                                                                                                                                                                                                                                        language
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        this
                                                                                                                                                                                                                                                        Arbitration
                                                                                                                                                                                                                                                        Agreement
                                                                                                                                                                                                                                                        section
                                                                                                                                                                                                                                                        will
                                                                                                                                                                                                                                                        be
                                                                                                                                                                                                                                                        null
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        void.
                                                                                                                                                                                                                                                        This
                                                                                                                                                                                                                                                        arbitration
                                                                                                                                                                                                                                                        agreement
                                                                                                                                                                                                                                                        will
                                                                                                                                                                                                                                                        survive
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        termination
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        your
                                                                                                                                                                                                                                                        relationship
                                                                                                                                                                                                                                                        with
                                                                                                                                                                                                                                                        chatBCC.</span>
                                                                                                                                                                                                                                            </p>
                                                                                                                                                                                                                                            <p class="c11"><span class="c18 c0">Miscellaneous</span>
                                                                                                                                                                                                                                                <span
                                                                                                                                                                                                                                                class="c13">. You will
                                                                                                                                                                                                                                                    be responsible
                                                                                                                                                                                                                                                    for paying,
                                                                                                                                                                                                                                                    withholding,
                                                                                                                                                                                                                                                    filing,
                                                                                                                                                                                                                                                    and reporting
                                                                                                                                                                                                                                                    all taxes,
                                                                                                                                                                                                                                                    duties,
                                                                                                                                                                                                                                                    and other
                                                                                                                                                                                                                                                    governmental
                                                                                                                                                                                                                                                    assessments
                                                                                                                                                                                                                                                    associated
                                                                                                                                                                                                                                                    with your
                                                                                                                                                                                                                                                    activity
                                                                                                                                                                                                                                                    in connection
                                                                                                                                                                                                                                                    with the
                                                                                                                                                                                                                                                    Services,
                                                                                                                                                                                                                                                    provided
                                                                                                                                                                                                                                                    that the
                                                                                                                                                                                                                                                    chatBCC
                                                                                                                                                                                                                                                    may, in
                                                                                                                                                                                                                                                    its sole
                                                                                                                                                                                                                                                    discretion,
                                                                                                                                                                                                                                                    do any
                                                                                                                                                                                                                                                    of the
                                                                                                                                                                                                                                                    foregoing
                                                                                                                                                                                                                                                    on your
                                                                                                                                                                                                                                                    behalf
                                                                                                                                                                                                                                                    or for
                                                                                                                                                                                                                                                    itself
                                                                                                                                                                                                                                                    as it sees
                                                                                                                                                                                                                                                    fit. </span>
                                                                                                                                                                                                                                                    <span
                                                                                                                                                                                                                                                    class="c2 c17">The failure
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        either
                                                                                                                                                                                                                                                        you
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        us
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        exercise,
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        way,
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        right
                                                                                                                                                                                                                                                        herein
                                                                                                                                                                                                                                                        shall
                                                                                                                                                                                                                                                        not
                                                                                                                                                                                                                                                        be
                                                                                                                                                                                                                                                        deemed
                                                                                                                                                                                                                                                        a waiver
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        further
                                                                                                                                                                                                                                                        rights
                                                                                                                                                                                                                                                        hereunder.
                                                                                                                                                                                                                                                        If
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        provision
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        these
                                                                                                                                                                                                                                                        Terms
                                                                                                                                                                                                                                                        are
                                                                                                                                                                                                                                                        found
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        be
                                                                                                                                                                                                                                                        unenforceable
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        invalid,
                                                                                                                                                                                                                                                        that
                                                                                                                                                                                                                                                        provision
                                                                                                                                                                                                                                                        will
                                                                                                                                                                                                                                                        be
                                                                                                                                                                                                                                                        limited
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        eliminated,
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        minimum
                                                                                                                                                                                                                                                        extent
                                                                                                                                                                                                                                                        necessary,
                                                                                                                                                                                                                                                        so
                                                                                                                                                                                                                                                        that
                                                                                                                                                                                                                                                        these
                                                                                                                                                                                                                                                        Terms
                                                                                                                                                                                                                                                        shall
                                                                                                                                                                                                                                                        otherwise
                                                                                                                                                                                                                                                        remain
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        full
                                                                                                                                                                                                                                                        force
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        effect
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        enforceable.
                                                                                                                                                                                                                                                        You
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        chatBCC
                                                                                                                                                                                                                                                        agree
                                                                                                                                                                                                                                                        that
                                                                                                                                                                                                                                                        these
                                                                                                                                                                                                                                                        Terms
                                                                                                                                                                                                                                                        are
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        complete
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        exclusive
                                                                                                                                                                                                                                                        statement
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        mutual
                                                                                                                                                                                                                                                        understanding
                                                                                                                                                                                                                                                        between
                                                                                                                                                                                                                                                        you
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        chatBCC,
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        that
                                                                                                                                                                                                                                                        these
                                                                                                                                                                                                                                                        Terms
                                                                                                                                                                                                                                                        supersede
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        cancel
                                                                                                                                                                                                                                                        all
                                                                                                                                                                                                                                                        previous
                                                                                                                                                                                                                                                        written
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        oral
                                                                                                                                                                                                                                                        agreements,
                                                                                                                                                                                                                                                        communications
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        other
                                                                                                                                                                                                                                                        understandings
                                                                                                                                                                                                                                                        relating
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        the
                                                                                                                                                                                                                                                        subject
                                                                                                                                                                                                                                                        matter
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        these
                                                                                                                                                                                                                                                        Terms.
                                                                                                                                                                                                                                                        You
                                                                                                                                                                                                                                                        hereby
                                                                                                                                                                                                                                                        acknowledge
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        agree
                                                                                                                                                                                                                                                        that
                                                                                                                                                                                                                                                        you
                                                                                                                                                                                                                                                        are
                                                                                                                                                                                                                                                        not
                                                                                                                                                                                                                                                        an
                                                                                                                                                                                                                                                        employee,
                                                                                                                                                                                                                                                        agent,
                                                                                                                                                                                                                                                        partner,
                                                                                                                                                                                                                                                        or
                                                                                                                                                                                                                                                        joint
                                                                                                                                                                                                                                                        venture
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        chatBCC,
                                                                                                                                                                                                                                                        and
                                                                                                                                                                                                                                                        you
                                                                                                                                                                                                                                                        do
                                                                                                                                                                                                                                                        not
                                                                                                                                                                                                                                                        have
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        authority
                                                                                                                                                                                                                                                        of
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        kind
                                                                                                                                                                                                                                                        to
                                                                                                                                                                                                                                                        bind
                                                                                                                                                                                                                                                        chatBCC
                                                                                                                                                                                                                                                        in
                                                                                                                                                                                                                                                        any
                                                                                                                                                                                                                                                        respect
                                                                                                                                                                                                                                                        whatsoever.</span>
                                                                                                                                                                                                                                            </p>
                                                                                                                                                                                                                                            <p class="c25"><span class="c2 c17">Except as expressly set forth in the sections above regarding the Apple Application and the arbitration agreement, you and chatBCC agree there are no third-party beneficiaries intended under these Terms.</span></p>
`

export const TermsScreen = () => {
  return (
    <section className="TermsContent light-mode">
      <div className="mx-auto max-w-3xl overflow-x-auto px-4 py-8">
        <div dangerouslySetInnerHTML={{ __html: termsContent }} />
      </div>
    </section>
  )
}
