import { Link as RRLink, LinkProps as RRLinkProps } from "react-router-dom"
import { cn } from "../common/cn"

type LinkProps = RRLinkProps & {
  variant?: "default" | "muted"
}

/**
 * Text link component that supports both internal and external links
 */
export const Link = ({ variant = "default", ...props }: LinkProps) => {
  const className = cn(
    "underline-offset-4 hover:underline",
    {
      "text-primary": variant === "default",
      "text-muted-foreground": variant === "muted",
    },
    props.className
  )

  // If href starts with http/https, use regular anchor tag
  if (props.to?.toString().match(/^https?:\/\//)) {
    return <a href={props.to.toString()} className={className} {...props} />
  }

  return <RRLink {...props} className={className} />
}
